import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import "./deposite_rate.css"
import { useTranslation } from "react-i18next";
import useLanguage from "../customHook/useLanguage";
const DepositeRate = () => {
  const [depositAmount, setDepositAmount] = useState(100000);
  const [annualRate, setAnnualRate] = useState(5);
  const [years, setYears] = useState(5);

  const { t } = useTranslation('interest');
  const lanuageState = useLanguage();
  const calculateMaturity = () => {
    const maturity = depositAmount * Math.pow(1 + annualRate / 100, years);
    return maturity.toFixed(2);
  };

  const totalInterestEarned = () => {
    return (calculateMaturity() - depositAmount).toFixed(2);
  };

  const chartData = {
    labels: lanuageState === "english" ? ["Principal", "Interest Earned"] : ["मूळ", "व्याज मिळाले"],
    datasets: [
      {
        data: [depositAmount, totalInterestEarned()],
        backgroundColor: ["#d12420", "#f9c313"],
        hoverBackgroundColor: ["#d12420", "#f9c313"],
      },
    ],
  };

  return (
    <section className="deposit-content">
      <div className="container">
        <div className="deposit-calculator">
          <div id="deposit-calculator">
            <div className="row">
              <div className="col-xl-7 col-lg-12">
                <div className="bg-white shadow-sm rounded-3 p-3">
                  <h4 className="fw-semibold txt-footer">{t("deposit_title")}</h4>

                  {/* Deposit Amount */}
                  <div className="mt-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-md-row gap-3 my-3">
                      <h5 className="mb-0">{t("deposit_amount")}</h5>
                      <input
                        type="text"
                        className="border-0 outline-none p-2"
                        style={{ backgroundColor: "#eeeeee" }}
                        value={depositAmount}
                      />
                    </div>
                    <input
                      type="range"
                      className="custom-range"
                      min="10000"
                      max="1000000"
                      step="10000"
                      value={depositAmount}
                      onChange={(e) => setDepositAmount(Number(e.target.value))}
                      style={{
                        background: `linear-gradient(to right, #d12420 ${((depositAmount - 10000) / (1000000 - 10000)) * 100
                          }%, #ddd ${((depositAmount - 10000) / (1000000 - 10000)) * 100
                          }%)`,
                      }}
                    />
                    <label className="fw-semibold fs-5 mt-2">
                      ₹{depositAmount}
                    </label>
                  </div>

                  {/* Annual Interest Rate */}
                  <div className="mt-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-md-row gap-3 my-3">
                      <h5 className="mb-0">{t("annual_interest_rate")}</h5>
                      <input
                        type="text"
                        className="border-0 outline-none p-2"
                        style={{ backgroundColor: "#eeeeee" }}
                        value={annualRate}
                      />
                    </div>
                    <input
                      type="range"
                      className="custom-range"
                      min="1"
                      max="15"
                      step="0.1"
                      value={annualRate}
                      onChange={(e) => setAnnualRate(Number(e.target.value))}
                      style={{
                        background: `linear-gradient(to right, #d12420 ${((annualRate - 1) / (15 - 1)) * 100
                          }%, #ddd ${((annualRate - 1) / (15 - 1)) * 100}%)`,
                      }}
                    />
                    <label className="fw-semibold fs-5 mt-2">
                      {annualRate}%
                    </label>
                  </div>

                  {/* Tenure */}
                  <div className="mt-4">
                    <div className="d-flex align-items-center justify-content-between flex-column flex-md-row gap-3 my-3">
                      <h5 className="mb-0">{t('tenure')}</h5>
                      <input
                        type="text"
                        className="border-0 outline-none p-2"
                        style={{ backgroundColor: "#eeeeee" }}
                        value={years}
                      />
                    </div>
                    <input
                      type="range"
                      className="custom-range"
                      min="1"
                      max="30"
                      step="1"
                      value={years}
                      onChange={(e) => setYears(Number(e.target.value))}
                      style={{
                        background: `linear-gradient(to right, #d12420 ${((years - 1) / (30 - 1)) * 100
                          }%, #ddd ${((years - 1) / (30 - 1)) * 100}%)`,
                      }}
                    />
                    <label className="fw-semibold fs-5 mt-2">
                      {years} {t('years')}
                    </label>
                  </div>

                  {/* Maturity Result */}
                  <div className="text-center p-3">
                    <h5 className="d-inline-flex bg-secondary-theme p-2">
                      {t("maturity_amount")} ₹{calculateMaturity()}
                    </h5>
                  </div>
                  <div className="note mt-4">
                    <p>
                      <strong>{t("note")}</strong>
                      {t("note_deposit")}
                    </p>
                  </div>
                </div>
              </div>

              {/* Chart Section */}
              <div className="col-xl-5">
                <div className="maturity-calculation p-3 bg-white shadow-sm rounded-3">
                  <div className="text-center chart-show">
                    <Doughnut data={chartData} />
                  </div>
                  <div className="mt-3 mt-lg-5 text-center">
                    <h3 className="txt-footer">
                      {t("total_interest_rate")} ₹{totalInterestEarned()}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table striped bordered hover responsive className="table-modern-new mt-4">
          <thead>
            <tr>
              <th>{t("sr_no")}</th>
              <th>{t("type_deposits")}</th>
              <th>{t("interest_rate")}</th>
              <th>{t("terms_conditions")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                {t("deposit_one_title")} <br />
                {t("deposit_one_period")} <br />
                
              </td>
              <td>
                {t("deposit_interest_one")} <br />
                {t("deposit_interest_sr_one")}
              </td>
              <td colSpan={3}>
                <ul>
                  <li>{t("deposit_one_terms_one")}</li>
                  <li>{t("deposit_one_terms_two")}</li>
                  
                </ul>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>{t("deposit_two_title")}<br />
                {t("deposit_two_period")} <br />
                {t("deposit_two_desc")}
              </td>
              <td>
                {t("deposit_interest_two")}
              </td>
              <td colSpan={3}>
                <ul>
                  <li>{t("deposit_two_terms_one")}</li>
                  <li>{t("deposit_two_terms_two")}</li>
                
                </ul>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                {t("deposit_three_title")} <br /> {t("deposit_three_period")} <br />
                {t("deposit_three_desc")}
              </td>
              <td>
                {t("deposit_interest_three")} 
              </td>
              <td colSpan={3}>
                <ul>
                  <li>{t("deposit_three_terms_one")}</li>
                  <li>{t("deposit_three_terms_two")}</li>
                 
                </ul>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                {t("deposit_four_title")}<br /> {t("deposit_four_period")}
              </td>
              <td>
                {t("deposit_interest_four")}
              </td>
              <td colSpan={3}>
                <ul>
                  <li>{t("deposit_four_terms_one")}</li>
                  <li>{t("deposit_four_terms_two")}</li>
                  <li>{t("deposit_four_terms_three")}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                {t("deposit_five_title")} <br />
                {t("deposit_five_desc")}  <br />
                <p className="fw-bold">{t("identity_sub_title")}</p>
                <ol>
                  <li>{t("identity_list_one")}</li>
                  <li>{t("identity_list_two")}</li>
                  <li>{t("identity_list_three")}</li>
                  <li>{t("identity_list_four")}</li>
                  <li>{t("identity_list_five")}</li>
                  <li>{t("identity_list_six")}</li>
                </ol>

                <p className="fw-bold">{t("residential_title")}</p>
                <ol>
                  <li>{t("residential_list_one")}</li>
                  <li>{t("residential_list_two")}</li>
                  <li>{t("residential_list_three")}</li>
                  <li>{t("residential_list_four")}</li>
                  <li>{t("residential_list_five")}</li>
                </ol>
              </td>
              <td>
                {t("deposit_interest_five")}
              </td>
              <td colSpan={3}>
                <ul>
                  <li>{t("deposit_five_terms_one")}</li>
                  <li>{t("deposit_five_terms_two")}</li>
                  <li>{t("deposit_five_terms_three")}</li>
                  <li>{t("deposit_five_terms_four")}</li>
                  <li>{t("deposit_five_terms_five")}</li>
                  <li>{t("deposit_five_terms_six")}</li>
                  <li>{t("deposit_five_terms_seven")}</li>
                  <li>{t("deposit_five_terms_eight")}</li>
                  <li>{t("deposit_five_terms_nine")}</li>
                  <li>{t("deposit_five_terms_ten")}</li>
                  <li>{t("deposit_five_terms_eleven")}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>
                {t("deposit_six_title")} <br />
                {t("deposit_six_period")}
              </td>
              <td>
                {t("deposit_interest_six")}
              </td>
              <td colSpan={3}>
                <ul>
                
                  <li>{t("deposit_six_terms_two")}</li>
                  <li>{t("deposit_six_terms_three")}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>
                {t("deposit_seven_title")}
                <br />
                {t("deposit_seven_period")}
              </td>
              <td>
                {t("deposit_interest_seven")}
              </td>
              <td colSpan={3}>
                <ul>
                  <li>{t("deposit_seven_terms_one")}</li>
                  <li>{t("deposit_seven_terms_two")}</li>
                  <li>{t("deposit_seven_terms_three")}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>
                {t("deposit_eight_title")}<br />
                <Table striped bordered hover responsive className="table-modern-new ">
                  <thead>
                    <tr><td colSpan={3} className="bg-success text-white">{t("table_title_one")}</td></tr>
                    <tr>
                      <th>{t("table_header_one")}</th>
                      <th>{t("table_header_two")}</th>
                      <th>{t("table_header_three")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("rs")} 10000</td>
                      <td>{t("rs")} 67</td>
                      <td>{t("rs")} 69</td>
                    </tr>
                    <tr>
                      <td>{t("rs")} 25000</td>
                      <td>{t("rs")} 167</td>
                      <td>{t("rs")}172</td>
                    </tr>
                    <tr>
                      <td>{t("rs")} 50000</td>
                      <td>{t("rs")} 333</td>
                      <td>{t("rs")} 344</td>
                    </tr>
                    <tr>
                      <td>{t("rs")} 100000</td>
                      <td>{t("rs")} 667</td>
                      <td>{t("rs")} 688</td>
                    </tr>
                    <tr><td colSpan={3} className="bg-success text-white" >{t("table_title_two")}</td></tr>
                    <tr>
                      <td>{t("rs")} 10000</td>
                      <td>{t("rs")} 63</td>
                      <td>{t("rs")} 65</td>
                    </tr>
                    <tr>
                      <td>{t("rs")} 25000</td>
                      <td>{t("rs")} 156</td>
                      <td>{t("rs")} 161</td>
                    </tr>
                    <tr>
                      <td>{t("rs")} 50000</td>
                      <td>{t("rs")} 313</td>
                      <td>{t("rs")} 323</td>
                    </tr>
                    <tr>
                      <td>{t("rs")} 100000</td>
                      <td>{t("rs")} 625</td>
                      <td>{t("rs")} 646</td>
                    </tr>
                    <tr><td colSpan={3} className="bg-success text-white">{t("table_title_three")}</td></tr>
                    <tr>
                      <td>{t("rs")} 10000</td>
                      <td>{t("rs")} 58</td>
                      <td>{t("rs")} 60</td>
                    </tr>
                    <tr>
                      <td>{t("rs")} 25000</td>
                      <td>{t("rs")} 146</td>
                      <td>{t("rs")} 151</td>
                    </tr>
                    <tr>
                      <td>{t("rs")} 50000</td>
                      <td>{t("rs")} 292</td>
                      <td>{t("rs")} 302</td>
                    </tr>
                    <tr>
                      <td>{t("rs")} 100000</td>
                      <td>{t("rs")} 583</td>
                      <td>{t("rs")} 604</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
              <td>
              {t("deposit_interest_eight")}
              </td>
              <td colSpan={3}>
                <ul>
                  <li>{t("deposit_eight_terms_one")}</li>
                  <li>{t("deposit_eight_terms_two")}</li>
                  <li>{t("deposit_eight_terms_three")}</li>
                  <li>{t("deposit_eight_terms_four")}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>
               {t("deposit_nine_title")}<br />
                {t("deposit_nine_period_title")}
                <ol>
                  <li>{t("deposit_nine_period_one")}</li>
                  <li>{t("deposit_nine_period_two")}</li>
                </ol>
                {t("deposit_nine_period_sub_title")}
              </td>
              <td>
                {t("deposit_interest_nine_one")} <br />
                {t("deposit_interest_nine_two")}
              </td>
              <td colSpan={3}>
                <ul>
                  <li>{t("deposit_nine_terms_one")}</li>
                  <li>{t("deposit_nine_terms_three")}</li>
                  <li>{t("deposit_nine_terms_four")}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>
                {t("deposit_ten_title")} <br />
                <Table striped bordered hover responsive className="table-modern-new ">
                  <thead>
                    <tr>
                      <th>{t("ten_table_header")} </th>
                      <th>1 {t("year")} 8.00%</th>
                      <th>2 {t("year")} 8.5%</th>
                      <th>3 {t("year")} 9.00%</th>
                      <th>4 {t("year")} 9.00%</th>
                      <th>5 {t("year")} 9.00%</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("rs")} 100</td>
                      <td>{t("rs")} 1252</td>
                      <td>{t("rs")} 2612</td>
                      <td>{t("rs")} 4100</td>
                      <td>{t("rs")} 5682</td>
                      <td>{t("rs")} 7372</td>
                    </tr>
                    <tr>
                      <td>{t("rs")} 500</td>
                      <td>{t("rs")} 6260</td>
                      <td>{t("rs")} 13060</td>
                      <td>{t("rs")} 20500</td>
                      <td>{t("rs")} 28410</td>
                      <td>{t("rs")} 36860</td>
                    </tr>
                    <tr>
                      <td>{t("rs")} 1000</td>
                      <td>{t("rs")} 12520</td>
                      <td>{t("rs")} 26120</td>
                      <td>{t("rs")} 41000</td>
                      <td>{t("rs")} 56820</td>
                      <td>{t("rs")} 73720</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
              <td>
              {t("deposit_interest_ten")}
              </td>
              <td colSpan={3}>
                <ul>
                  <li>{t("deposit_ten_terms_one")}</li>
                  <li>{t("deposit_ten_terms_two")}</li>
                  <li>{t("deposit_ten_terms_three")}</li>
                  <li>{t("deposit_ten_terms_four")}</li>
                  <li>{t("deposit_ten_terms_six")}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>
                {t("deposit_ele_title")}<br />
                <p className="fw-bold">{t("deposit_ele_sub_title_two")}</p>
                <p className="fw-bold">{t("deposit_ele_sub_title_three")}</p>
                <p className="fw-bold">{t("deposit_ele_sub_title_four")}</p>
                <p className="fw-bold">{t("deposit_ele_sub_title_five")}</p>
                <p className="fw-bold">{t("deposit_ele_sub_title_six")}</p>
              </td>
              <td>
                <Table striped bordered hover responsive className="table-modern-new ">
                  <thead>
                    <tr>
                      <th>{t("deposit_table_head_one")}</th>
                      <th>{t("deposit_table_head_two")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr>
                      <td>5.50%</td>
                      <td>6.00%</td>
                    </tr>
                    <tr>
                      <td>6.00%</td>
                      <td>6.25%</td>
                    </tr>
                    <tr>
                      <td>6.25%</td>
                      <td>6.50%</td>
                    </tr>
                    <tr>
                      <td>7.00%</td>
                      <td>7.25%</td>
                    </tr>
                    <tr>
                      <td>7.50%</td>
                      <td>8.00%</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
              <td colSpan={3}>
                <ul>
                  <li>{t("deposit_ele_terms_one")}</li>
                  <li>{t("deposit_ele_terms_two")}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>
                {t("deposit_twel_title")} <br />
                <Table striped bordered hover responsive className="table-modern-new ">
                  <thead>
                    <tr>
                      <th>{t("twel_table_head_one")}</th>
                      <th>{t("twel_table_head_two")}</th>
                      <th>{t("twel_table_head_three")}</th>
                      <th>{t("twel_table_head_four")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{t("rs")} 2440</td>
                      <td>3 {t("years")}</td>
                      <td>{t("rs")} 1 {t("lakh")}</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>{t("rs")} 1760</td>
                      <td>4 {t("years")}</td>
                      <td>{t("rs")} 1{t("lakh")}</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Rs 1360</td>
                      <td>5 {t("years")}</td>
                      <td>{t("rs")} 1 {t("lakh")}</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
              <td>
                -
              </td>
              <td colSpan={3}>
                <ul>
                  <li>{t("deposit_twel_terms_one")}</li>
                  <li>{t("deposit_twel_terms_two")}</li>
                  <li>
                  {t("deposit_twel_terms_three")}
                  </li>
                  <li>{t("deposit_twel_terms_five")}</li>
                  <li>{t("deposit_twel_terms_six")}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>13</td>
              <td>
               {t("deposit_thirteen_title")}
              </td>
              <td>
                {t("deposit_interest_thirteen")}
              </td>
              <td colSpan={3}>
                <ul>
                  {t('deposit_thirteen_terms')}
                </ul>
              </td>
            </tr>
            <tr>
              <td>14</td>
              <td>
               {t("deposit_fourteen_title")}
              </td>
              <td>
                {t("deposit_interest_fourteen")}
              </td>
              <td colSpan={3}>
                <ul>
                  {t('deposit_fourteen_terms')}
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>

      </div>
    </section>
  );
};

export default DepositeRate;

import React from 'react'
import { ClipLoader } from 'react-spinners'

function LoadingLayout() {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <ClipLoader
            color={"#d12420"}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    </div>
  )
}

export default LoadingLayout;
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useLanguage from '../customHook/useLanguage';
import { useParams } from 'react-router-dom';
import { getNewsAndEventsDetails } from '../http/httpRequest';
import { useQuery } from '@tanstack/react-query';
import LoadingLayout from '../loadingLayout/LoadingLayout';
import "./news&eventsdetails.css"

function NewsAndEventsDetails() {
  const { id } = useParams();
  const languageState = useLanguage();

  const { data: newsEventsDetails, isLoading: newsEventsDetailsIsLoading } = useQuery({
    queryKey: ["news-events-details", languageState],
    queryFn: () => getNewsAndEventsDetails(id),
    gcTime: Infinity,
    staleTime: 0,
  });

  if (newsEventsDetailsIsLoading) return <LoadingLayout />;


  return (
    <section className="news-details mt-4 mb-4">
      {newsEventsDetails?.data && (
        <>
          <div className="container">
            <img
              className="responsive-image"
              src={newsEventsDetails?.data[languageState]?.news_events_image}
              alt="News Event"
            />
          </div>
        </>
      )}
    </section>
  )
}

export default NewsAndEventsDetails;
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "./branches.css";
import { CiSearch } from "react-icons/ci";
import { SiGooglemaps } from "react-icons/si";
import { data } from "./data.js";
import { divisionsData } from "./divisionData.js";
import useLanguage from "../customHook/useLanguage.jsx";
import { useTranslation } from "react-i18next";

function Branches() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDivision, setSelectedDivision] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const languageState = useLanguage();

  const { t } = useTranslation("otherlinks");

  const allDivisions = divisionsData.divisions.map(
    (division) => division.divisionName[languageState]
  );

  const filteredBranches =
    selectedDivision !== ""
      ? divisionsData.divisions
          .find(
            (division) =>
              division.divisionName[languageState] === selectedDivision
          )
          ?.branches.map((branch) => branch.branchName[languageState]) || []
      : divisionsData.divisions.flatMap((division) =>
          division.branches.map((branch) => branch.branchName[languageState])
        );

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const filteredData = data.filter((item) => {
    const matchesSearch =
      item[languageState].division.toLowerCase().includes(searchQuery) ||
      item[languageState].branch.toLowerCase().includes(searchQuery) ||
      item[languageState].address.toLowerCase().includes(searchQuery) ||
      item[languageState].contact.includes(searchQuery);

    const matchesDivision =
      !selectedDivision ||
      item[languageState].division === selectedDivision;

    const matchesBranch =
      !selectedBranch || item[languageState].branch === selectedBranch;

    return matchesSearch && matchesDivision && matchesBranch;
  });

  useEffect(()=> {
    setSearchQuery("");
    setSelectedBranch("");
    setSelectedDivision("");
  },[languageState])

  return (
    <div className="container branch-content">
      <div className="row">
        <div className="text-center mb-3 my-lg-4">
          <h2 className="fw-normal d-inline-block position-relative custom-heading">
            {t("our")} <span className="head-sub-text">{t("branches")}</span>
          </h2>
        </div>
      </div>
      <div className="row height d-flex justify-content-center align-items-center gap-2 gap-md-0 gap-lg-0">
        <div className="col-md-4">
          <select
            value={selectedDivision}
            className="form-select custom-select"
            onChange={(e) =>{
                setSelectedBranch("");
                setSelectedDivision(e.target.value);
            }}
            style={{
              padding: "14px 10px",
              borderRadius: "8px",
              border: "1px solid #ddd",
              fontSize: "16px",
              width: "100%",
              textAlign: "start",
            }}
          >
            <option value="" >
             {t("select_division")}
            </option>
            {allDivisions.map((division) => (
              <option key={division} value={division}>
                {division}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4">
          <select
            value={selectedBranch}
            className="form-select custom-select"
            onChange={(e) => setSelectedBranch(e.target.value)}
            style={{
              padding: "14px 10px",
              borderRadius: "8px",
              border: "1px solid #ddd",
              fontSize: "16px",
              width: "100%",
              textAlign: "start",
            }}
          >
            <option value="" >
              {t("select_branch")}
            </option>
            {filteredBranches.map((branch) => (
              <option key={branch} value={branch}>
                {branch}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4">
          <div className="form">
            <input
              type="text"
              className="form-control form-input"
              placeholder={t("search")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <span className="left-pan">
              <CiSearch />
            </span>
          </div>
        </div>
      </div>
      {filteredData.length > 0 ? (
        <Table striped bordered hover responsive className="table-modern">
          <thead>
            <tr>
              <th>{t("sr_no")}</th>
              <th>{t("division")}</th>
              <th>{t("branch_divisiona_office")}</th>
              <th>{t("address")}</th>
              <th style={{ whiteSpace: "nowrap", width: "150px" }}>{t("contact_no")}</th>
              <th>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row[languageState].division}</td>
                <td>{row[languageState].branch}{row[languageState].main_office && (<span className="fs-4 text-danger blink">*</span>)}</td>
                <td>{row[languageState].address}</td>
                <td className="contact"><a href={`tel:${row[languageState].contact}`}>{row[languageState].contact}</a></td>
                <td className="location">
                  <a href={row[languageState].mapLocation} target="_blanks">
                    <SiGooglemaps size={25} />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="d-flex justify-content-center my-4">
          <h4>No search data available...</h4>
        </div>
      )}
    </div>
  );
}

export default Branches;

import React from "react";
import Table from "react-bootstrap/Table";
import "./development.css";
import { useTranslation } from "react-i18next";


const Development = () => {
    const data = [
        { year: "1990", memberNo: "690", capital: "1.11", deposits: "6.18", fund: "-", loans: "4.10", profit: "0", class: "-" },
        { year: "1991", memberNo: "714", capital: "1.96", deposits: "9.80", fund: "-", loans: "9.53", profit: "0", class: "B" },
        { year: "1992", memberNo: "793", capital: "3.10", deposits: "16.40", fund: "0.01", loans: "17.47", profit: "0.27", class: "B" },
        { year: "1993", memberNo: "812", capital: "4.43", deposits: "19.50", fund: "0.02", loans: "23.41", profit: "1.00", class: "A" },
        { year: "1994", memberNo: "879", capital: "6.37", deposits: "27.42", fund: "0.81", loans: "34.53", profit: "0.88", class: "A" },
        { year: "1995", memberNo: "942", capital: "8.80", deposits: "33.48", fund: "1.13", loans: "44.81", profit: "1.92", class: "A" },
        { year: "1996", memberNo: "983", capital: "11.82", deposits: "46.68", fund: "1.61", loans: "61.55", profit: "1.47", class: "A" },
        { year: "1997", memberNo: "1029", capital: "14.94", deposits: "52.13", fund: "1.99", loans: "68.28", profit: "2.89", class: "A" },
        { year: "1998", memberNo: "1052", capital: "18.52", deposits: "70.52", fund: "2.72", loans: "93.40", profit: "2.62", class: "A" },
        { year: "1999", memberNo: "1143", capital: "24.10", deposits: "94.42", fund: "2.39", loans: "132.57", profit: "5.07", class: "A" },
        { year: "2000", memberNo: "1193", capital: "31.24", deposits: "135.02", fund: "4.69", loans: "190.77", profit: "4.32", class: "A" },
        { year: "2001", memberNo: "1272", capital: "41.90", deposits: "239.00", fund: "5.85", loans: "312.06", profit: "4.76", class: "A" },
        { year: "2002", memberNo: "1471", capital: "51.11", deposits: "587.39", fund: "9.32", loans: "572.98", profit: "8.28", class: "A" },
        { year: "2003", memberNo: "1453", capital: "57.30", deposits: "1012.84", fund: "2.52", loans: "807.76", profit: "8.58", class: "A" },
        { year: "2004", memberNo: "1507", capital: "70.66", deposits: "1559.35", fund: "6.29", loans: "1119.06", profit: "11.88", class: "A" },
        { year: "2005", memberNo: "1599", capital: "91.33", deposits: "2296.81", fund: "10.81", loans: "1639.25", profit: "12.21", class: "A" },
        { year: "2006", memberNo: "1903", capital: "104.76", deposits: "2950.60", fund: "15.58", loans: "2322.15", profit: "7.94", class: "A" },
        { year: "2007", memberNo: "2036", capital: "131.21", deposits: "4112.38", fund: "18.80", loans: "3043.33", profit: "7.89", class: "A" },
        { year: "2008", memberNo: "2018", capital: "137.16", deposits: "5212.10", fund: "22.57", loans: "3645.72", profit: "8.57", class: "A" },
        { year: "2009", memberNo: "2566", capital: "246.48", deposits: "6590.11", fund: "29.94", loans: "4520.09", profit: "14.97", class: "A" },
        { year: "2010", memberNo: "4389", capital: "361.44", deposits: "8219.51", fund: "39.29", loans: "5720.18", profit: "19.67", class: "A" },
        { year: "2011", memberNo: "5360", capital: "551.71", deposits: "10435.65", fund: "55.15", loans: "7697.88", profit: "33.85", class: "A" },
        { year: "2012", memberNo: "8134", capital: "726.29", deposits: "12980.47", fund: "72.19", loans: "9303.53", profit: "65.77", class: "A" },
        { year: "2013", memberNo: "10885", capital: "820.91", deposits: "15791.96", fund: "139.64", loans: "11190.09", profit: "129.24", class: "A" },
        { year: "2014", memberNo: "15197", capital: "1022.66", deposits: "19737.69", fund: "182.41", loans: "13634.15", profit: "73.07", class: "A" },
        { year: "2015", memberNo: "19624", capital: "1255.92", deposits: "23990.15", fund: "298.53", loans: "16088.05", profit: "126.97", class: "A" }
      ];
      const { t } = useTranslation("about");
      
  return (
    <div className="container mt-5 development-content mb-4">
      <div className="text-center mb-3 my-lg-4">
        <h2 className="fw-normal  d-inline-block position-relative custom-heading">
          <span className="head-sub-text">{t('development_title')}</span>
        </h2>
      </div>
      <Table striped bordered hover responsive className="table-modern">
        <thead>
          <tr>
            <th>{t("sr_no")}</th>
            <th>{t("year")}</th>
            <th>{t("member_no")}</th>
            <th>{t("recovery_share_capital")}</th>
            <th>{t("deposit")}</th>
            <th>{t("reserve_funds")}</th>
            <th>{t("loans")}</th>
            <th>{t("profit")}</th>
            <th>{t("audit_class")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{row.year}</td>
              <td>{row.memberNo}</td>
              <td>{row.capital}</td>
              <td>{row.deposits}</td>
              <td>{row.fund}</td>
              <td>{row.loans}</td>
              <td>{row.profit}</td>
              <td>{row.class}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="text-center mb-3 my-lg-4">
        <h2 className="fw-normal d-inline-block position-relative custom-heading">
          <span className="head-sub-text">{t("growing_chart")}</span>
        </h2>
      </div>
      <div className="image-grid ">
        <img
          className="chart-image animate__animated animate__fadeInLeft"
          src="/chart1.png"
          alt="Chart 1"
        />
        <img
          className="chart-image animate__animated animate__fadeInRight"
          src="/chart2.png"
          alt="Chart 2"
        />
      </div>
    </div>
  );
};

export default Development;
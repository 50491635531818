import React from 'react';
import { Link } from 'react-router-dom';
import useLanguage from '../customHook/useLanguage';
import { useQuery } from '@tanstack/react-query';
import { getNewsAndEventsList } from '../http/httpRequest';
import LoadingLayout from '../loadingLayout/LoadingLayout';
import { useTranslation } from 'react-i18next';
import "./news&events.css"

function NewsAndEvents() {
    const languageState = useLanguage();
    const { t } = useTranslation();

    const { data: newsEventsData, isLoading: newsEventsIsLoading } = useQuery({
        queryKey: ["news-events", languageState],
        queryFn: getNewsAndEventsList,
        gcTime: Infinity,
        staleTime: 0,
    });

    if (newsEventsIsLoading) return <LoadingLayout />;

    return (
        <div className='news-events'>
            <div className='container mt-3 mt-lg-5'>
                <div className="text-center mb-3 my-lg-4">
                    <h2 className="fw-normal d-inline-block position-relative custom-heading">
                        {t("news")} <span>&</span> <span className="head-sub-text">{t("events")}</span>
                    </h2>
                </div>
                <div className="masonry-grid">
                    {
                        newsEventsData?.data && newsEventsData?.data[languageState]?.length > 0 ? (
                            newsEventsData.data[languageState].map((news) => (
                                <div className="masonry-item" key={news?.id}>
                                    <Link to={`/news&events-details/${news?.id}`}>
                                        <div className="news-cards">
                                            <div className="overflow-hidden position-relative">
                                                <img
                                                    src={news?.news_events_image}
                                                    alt={news?.news_events_title}
                                                    className="w-100"
                                                />
                                                <div className="p-2 position-absolute bottom-0 left-0 image-overlay-content w-100">
                                                    <p className="txt-12 mb-0">{news?.news_events_title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        ) : (
                            <div>No events data available</div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default NewsAndEvents;

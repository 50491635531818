import React, { useState } from 'react';
import "./career.css";
import useLanguage from '../customHook/useLanguage';
import { enquiryForJob, getCareerDetails } from '../http/httpRequest';
import { useMutation, useQuery } from '@tanstack/react-query';
import LoadingLayout from '../loadingLayout/LoadingLayout';
import { FaLongArrowAltRight } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import "animate.css";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { validateApplyForm } from '../utils/validateApplyForm';
// import Background_pattern from "Background_pattern.jpg"

function Career() {
    const languageState = useLanguage();
    const { t } = useTranslation("otherlinks");
    const { data: careerData, isLoading: careerDataIsLoading } = useQuery({
        queryKey: ["career", languageState],
        queryFn: getCareerDetails,
        gcTime: Infinity,
        staleTime: 0,
    });
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNo: "",
        skills: "",
        experience: "",
        experienceYears: "",
        experienceMonths: "",
    })

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phoneNo: "",
        skills: "",
        experience: "",
        experienceYears: "",
        experienceMonths: "",
    });

    const [file, setFile] = useState(null);
    const [jobId, setJobId] = useState('');
    const [fileError, setFileError] = useState("");

    const handleDownloadPdf = async (career) => {
        const pdfUrl = career?.career_pdf;

        try {
            const response = await fetch(pdfUrl);
            if (!response.ok) {
                throw new Error("Failed to fetch the PDF file.");
            }

            const blob = await response.blob(); // Create a blob from the response
            const blobUrl = window.URL.createObjectURL(blob);

            // Create an anchor element and trigger the download
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = career ? `${career?.career_title}.pdf` : "career_details.pdf"; // Set the desired file name
            document.body.appendChild(link);
            link.click();

            // Clean up the URL and remove the anchor element
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading PDF:", error.message);
            alert("Failed to download the PDF. Please try again.");
        }
    };

    const handleOpen = () => {
        setFormData({
            name: "",
            email: "",
            phoneNo: "",
            skills: "",
            experience: "",
            experienceYears: "",
            experienceMonths: "",
        });
        setShowModal(true)
    };
    const handleClose = () => {
        setFormData({
            name: "",
            email: "",
            phoneNo: "",
            skills: "",
            experience: "",
            experienceYears: "",
            experienceMonths: "",
        });
        setShowModal(false)
    };

    const handleOnChange = (e) => {
        setErrors({
            name: "",
            email: "",
            phoneNo: "",
            skills: "",
            experience: "",
            experienceYears: "",
            experienceMonths: "",
        })
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileType = file.type;
            if(fileType === "application/pdf") {
                setFile(file);
            } else {
                setFileError("Oops! It looks like the selected file is not a PDF. Please upload a PDF file.")
            }
        }
    };

    const {
        mutate: enquiryMutate,
    } = useMutation({
        mutationKey: ["reach-us"],
        mutationFn: enquiryForJob,
        onSuccess: (data) => {
            toast.success(data?.message);
            setFormData({
                name: "",
                email: "",
                phoneNo: "",
                skills: "",
                experience: "",
                experienceYears: "",
                experienceMonths: "",
            });
            setFile(null);
            setShowModal(false);
        },
        onError: (error) => {
            toast.error(error.message || "Something went wrong");
        },
    });

    const handleOnSubmit = async (e) => {
        e.preventDefault()
        if (!validateApplyForm(formData, setErrors)) return;
        const dataSend = new FormData();

        dataSend.append("enquiry_name", formData.name);
        dataSend.append("enquiry_email", formData.email);
        dataSend.append("enquiry_phone", formData.phoneNo);
        if (formData.experience === "experience") {
            dataSend.append("enquiry_experience", `${formData.experienceYears}.${formData.experienceMonths}`);
        }
        dataSend.append("enquiry_skills", formData.skills);
        dataSend.append("enquiry_status", formData.experience);
        dataSend.append("enquiry_resume", file);
        dataSend.append("enquiry_job_id", jobId);

        await enquiryMutate({ dataSend });
    };
    if (careerDataIsLoading) return <LoadingLayout />;

    return (
        <div className="container">
            <div className="text-center mb-3 my-lg-4">
                <h2 className="fw-normal d-inline-block position-relative custom-heading text-uppercase">
                    {t("currrent_job")}
                    <span className="head-sub-text ms-2 text-uppercase">{t("openings")}</span>
                </h2>
            </div>
            <div className="text-center mb-3 my-lg-4">
                <h4>{t("featured_jobs")}</h4>
            </div>

            <div className="row">
                {careerData?.data && careerData?.data[languageState]?.length > 0 ? (
                    careerData?.data[languageState]?.map((career) => {
                        return (
                            <div className="col-lg-6 col-md-12 col-12 mb-4" key={career?.id}>
                                <div className="card job-card" >
                                    <div className="card-body d-flex align-items-center gap-3">
                                        <img src={career?.career_photo} alt="Job 1" className="job-image" />
                                        <div>
                                            <h5 className="card-title">{career?.career_title}</h5>
                                            <p className="card-text">
                                                <span className="highlight"><FaLongArrowAltRight /> {t("experience")} : </span>{career?.career_experience}
                                            </p>
                                            <p className="card-text">
                                                <span className="highlight"><FaLongArrowAltRight /> {t("skills")} : </span>{career?.career_skills}
                                            </p>
                                            <p className="card-text">
                                                <span className="highlight"><FaLongArrowAltRight /> {t("location")}: </span>{career?.career_location_branch}
                                            </p>
                                            <div className='d-flex gap-3 mt-2 justify-content-lg-start justify-content-center'>
                                                <button className="btn btn-color" onClick={() => {
                                                    handleOpen();
                                                    setJobId(career?.id);
                                                }}>{t("apply_now")}</button>
                                                <button className="btn btn-color" onClick={() => handleDownloadPdf(career)}>{t("download")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className='d-flex justify-content-center'>{t('available_jobs')}</div>
                )}
            </div>
            <Modal
                show={showModal}
                onHide={handleClose}
                animation={true}
                dialogClassName="animated animate__backInDown modal-dialog-centered"
                className='animate__animated animate__backInDown'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("fill_info")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <form className="enquiryForm" name="enquiryForm" onSubmit={handleOnSubmit}>
                                <ul className="noBullet">
                                    <li>
                                        <label htmlFor="name">{t("name")} <span className='text-danger fs-4'>*</span></label>
                                        <input
                                            type="text"
                                            className="inputsFields"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            placeholder={t('enter_name')}
                                            onChange={handleOnChange}
                                            required
                                        />
                                        {errors.name && <p className="text-danger fs-6">{errors.name}</p>}
                                    </li>
                                    <li>

                                        <label htmlFor="email">{t("email")} <span className='text-danger fs-4'>*</span></label>
                                        <input
                                            type="email"
                                            className="inputsFields"
                                            id="email"
                                            name="email"
                                            placeholder={t("enter_email")}
                                            value={formData.email}
                                            onChange={handleOnChange}
                                            required
                                        />
                                        {errors.email && <p className="text-danger fs-6">{errors.email}</p>}
                                    </li>
                                    <li>
                                        <label htmlFor="phoneNo">{t("phone_no")}<span className='text-danger fs-4'>*</span></label>
                                        <input
                                            type="number"
                                            className="inputsFields"
                                            id="phoneNo"
                                            name="phoneNo"
                                            placeholder={t("enter_phone_no")}
                                            value={formData.phoneNo}
                                            onChange={handleOnChange}
                                            required
                                        />
                                        {errors.phoneNo && <p className="text-danger fs-6">{errors.phoneNo}</p>}
                                    </li>

                                    <li>
                                        <label htmlFor="experience">{t("experience")} <span className='text-danger fs-4'>*</span></label>
                                        <select
                                            className="inputsFields"
                                            id="experience"
                                            name="experience"
                                            value={formData.experience}
                                            onChange={handleOnChange}
                                            required
                                        >
                                            <option value="">{t("select_experience")}</option>
                                            <option value="fresher">{t("fresher")}</option>
                                            <option value="experience">{t("experienced")}</option>
                                        </select>
                                        {errors.experience && <p className="text-danger fs-6">{errors.experience}</p>}
                                    </li>
                                    {formData.experience === "experience" && (
                                        <div className='d-flex'>
                                            <li className="me-2">
                                                <label htmlFor="experienceYears">{t("years")} <span className='text-danger fs-4'>*</span></label>
                                                <input
                                                    type="number"
                                                    className="inputsFields"
                                                    id="experienceYears"
                                                    name="experienceYears"
                                                    placeholder={t("enter_years")}
                                                    value={formData.experienceYears || ""}
                                                    onChange={handleOnChange}
                                                />
                                                {errors.experienceYears && <p className="text-danger fs-6">{errors.experienceYears}</p>}
                                            </li>
                                            <li>
                                                <label htmlFor="experienceMonths">{t("months")} <span className='text-danger fs-4'>*</span></label>
                                                <input
                                                    type="number"
                                                    className="inputsFields"
                                                    id="experienceMonths"
                                                    name="experienceMonths"
                                                    placeholder={t("enter_months")}
                                                    value={formData.experienceMonths || ""}
                                                    onChange={handleOnChange}
                                                />
                                            </li>
                                            {errors.experienceMonths && <p className="text-danger fs-6">{errors.experienceMonths}</p>}
                                        </div>
                                    )}
                                    <li>
                                        <label htmlFor="skills">{t("skills")} <span className='text-danger fs-4'>*</span></label>
                                        <input
                                            type="input"
                                            className="inputsFields"
                                            id="skills"
                                            name="skills"
                                            placeholder={t("enter_skills")}
                                            value={formData.skills}
                                            onChange={handleOnChange}
                                            required
                                        />
                                        {errors.skills && <p className="text-danger fs-6">{errors.skills}</p>}
                                    </li>
                                    <li>
                                        <label htmlFor="resume">{t("resume")} <span className='text-danger fs-4'>*</span></label>
                                        <input
                                            type="file"
                                            className="inputsFields"
                                            id="resume"
                                            name="resume"
                                            onChange={handleFileChange}
                                            required
                                            accept=".pdf"
                                        />
                                         {fileError && <p className="text-danger fs-6">{fileError}</p>}
                                    </li>

                                    <li id="center-btn" className="text-center">
                                        <button type="submit"
                                            id="join-btns"
                                            name="Submit"
                                            alt="Join"
                                            value="Join">{t("submit")}</button>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Career;

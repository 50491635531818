import React from "react";
import "./footer.css";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const footerBg = {
    backgroundImage:
      "url('https://arena.km.ua/wp-content/uploads/3538533.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "#fff",
  };
  return (
    <>
      <section className="footer-content" style={footerBg}>
        <footer className="footer-section">
          <div className="container">
            <div className="pt-5 pb-5">
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
                  <div className="footer-widget">
                    <div className="mb-2">
                      <Link
                        to="/"
                        className="d-flex gap-2 align-items-center"
                      >
                        <Image
                          src="/logo-theme.png"
                          alt="logo-theme"
                          height={100}
                        />
                        <span className="txt-footer">
                          {" "}
                          {t("footer_title")}
                        </span>
                      </Link>
                    </div>
                    <div className="footer-text">
                      <p>
                      {t("footer_info")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-6 mb-3">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>{t("useful_links")}</h3>
                    </div>
                    <ul className="list-unstyled check-list-arrow position-relative">
                      <li>
                        <Link to="/">{t("home")}</Link>
                      </li>
                      <li>
                      <Link to="/about/patsanstha">{t("about_us")}</Link>
                      </li>
                      <li>
                        <Link to="/news&events">{t("news_and_events")}</Link>
                      </li>
                      <li>
                        <Link to="/interest-rate/loan">{t("interest_rates")}</Link>
                      </li>
                      <li>
                        <Link to="/gallery/photo">{t("gallery")}</Link>
                      </li>
                      <li>
                        <Link to="/schemes">{t("schemes")}</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">{t("contact_us")}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-6 mb-3">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>{t("other_links")}</h3>
                    </div>
                    <ul className="list-unstyled check-list-arrow position-relative">
                      {/* <li>
                        <a href="#">Downloads</a>
                      </li> */}
                      <li>
                        <Link to="/branches">{t("branches")}</Link>
                      </li>
                      <li>
                      <Link to="/career">{t('career')}</Link>
                      </li>
                      <li>
                      <Link to="/blogs">{t("blogs")}</Link>
                      </li>
                      <li>
                        <Link to="/newspaper">{t('group_news')}</Link>
                      </li>
                      <li>
                        <Link to="/sitemap">{t("sitemap")}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>{t("contact_us")}</h3>
                    </div>
                    <div className="footer-text mb-25">
                      <p>
                        {t("address_footer")}
                      </p>
                    </div>

                    <div className="footer-social-icon mt-3">
                      <a href="https://www.facebook.com/profile.php?id=61571043789637&mibextid=ZbWKwL" target="_blanks">
                        <i className="bx bxl-facebook facebook-bg text-white fs-4" />
                      </a>
                      <a href="https://www.instagram.com/chhatrapatisambhajipatsanstha?igsh=bHl6M3BxMDk5andl" target="_blanks">
                        <i className="bx bxl-instagram instagram-gradient  text-white fs-4" />
                      </a>
                      <a href="https://www.youtube.com/channel/UCig6ddhkIpJPVEZG_kXH6Ww" target="_blanks">
                        <i className="bx bxl-youtube youtube-bg text-white fs-4" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-8 text-center text-lg-left">
                  <div className="copyright-text">
                    <p>
                     © 2025, Chhatrapati Sambhaji Maharaj Patsanstha All rights reserved. {" "} 
                      Developed by {" "}
                      <a href="https://neosao.com/" target="_blank" className="" >
                        Neosao Services Pvt. Ltd.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
};

export default Footer;

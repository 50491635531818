
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;

//get Banner 
export const getBannerData = async () => {
    try {
        const response = await axios.get(`${baseUrl}banner`);

        return response.data;
    } catch (error) {
  
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || "failed to get bannered data");
        } else {
            throw new Error("failed to get bannered data");
        }
    }
};

//get blogs data
export const getBlogsData = async ({
    limit=10,
    debounceSearch="",
    page=1,
    category=""
} ) => {
    try {
        const response = await axios.get(`${baseUrl}blog?search=${debounceSearch}&page=${page}&limit=${limit}&category=${category}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || "failed to get blog data");
        } else {
            throw new Error("failed to get blog data");
        }
    }
};

//getCategories
export const getCategoriesData = async () => {
    try {
        const response = await axios.get(`${baseUrl}category`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || "failed to get blog data");
        } else {
            throw new Error("failed to get blog data");
        }
    }
};

//blogsDetails
export const getBlogsDetails = async (slugs) => {
    try {
        const response = await axios.get(`${baseUrl}blog/${slugs}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || "failed to get blog data");
        } else {
            throw new Error("failed to get blog data");
        }
    }
};

//News and Events
export const getNewsAndEventsList = async () => {
    try {
        const response = await axios.get(`${baseUrl}newsevents`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || "failed to get news and events data");
        } else {
            throw new Error("failed to get news and events data");
        }
    }
};

export const getNewsAndEventsDetails = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}newsevents/${id}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || "failed to get news and events details");
        } else {
            throw new Error("failed to get news and events details");
        }
    }
};

//group news 
export const getGroupNews = async(id="") => {
    try {
        const response = await axios.get(`${baseUrl}groupnews/${id}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || "failed to get news and events details");
        } else {
            throw new Error("failed to get news and events details");
        }
    }
}

//Gallery
export const getGalleyCategories = async() => {
    try {
        const response = await axios.get(`${baseUrl}gallery`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || "failed to get news and events details");
        } else {
            throw new Error("failed to get news and events details");
        }
    }
}

export const getGalleyImages = async( id ) => {
    try {
        const response = await axios.get(`${baseUrl}gallery/${id}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || "failed to get news and events details");
        } else {
            throw new Error("failed to get news and events details");
        }
    }
}


//Contact_Us

export const contactUs = async ({ dataSend }) => {
    try {
      const response = await axios.post(`${baseUrl}contact`, dataSend);
  
      return response.data;
    } catch (error) {
      console.log("Error:", error.response ? error.response.data : error.message);
      throw error;
    }
  };

  //Scehmes
  export const getSchemeData = async ({ pageParam=1, limit= 20 }) => {
    try {
      const response = await axios.get(`${baseUrl}scheme?limit=${limit}&page=${pageParam}`);
      return response.data;
    } catch (error) {
      console.log("Error:", error.response ? error.response.data : error.message);
      throw error;
    }
  };

  export const getSchemesDetails = async ({ id }) => {
    try {
      const response = await axios.get(`${baseUrl}scheme/${id}`);
      return response.data;
    } catch (error) {
      console.log("Error:", error.response ? error.response.data : error.message);
      throw error;
    }
  };

  //Career 

  export const getCareerDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}career`);
      return response.data;
    } catch (error) {
      console.log("Error:", error.response ? error.response.data : error.message);
      throw error;
    }
  };

  export const enquiryForJob = async ({ dataSend }) => {
    try {
      const response = await axios.post(`${baseUrl}enquiry`, dataSend);
      return response.data;
    } catch (error) {
      console.log("Error:", error.response ? error.response.data : error.message);
      throw error;
    }
  };

  //Home page Schemes

  export const getSchemesDataHome = async () => {
    try {
      const response = await axios.get(`${baseUrl}scheme/home`);
      return response.data;
    } catch (error) {
      console.log("Error:", error.response ? error.response.data : error.message);
      throw error;
    }
  };

  export const getSchemesDetailsHome = async ({ id }) => {
    try {
      const response = await axios.get(`${baseUrl}scheme/homedetails/${id}`);
      return response.data;
    } catch (error) {
      console.log("Error:", error.response ? error.response.data : error.message);
      throw error;
    }
  };
import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import "animate.css";

function FixedButtons() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const openWhatsApp = () => {
        const whatsappURL = "https://api.whatsapp.com/send/?phone=918530030700&text&type=phone_number&app_absent=0";
        window.open(whatsappURL, "_blank");
    }

    return (
        <>
            {
                isVisible && (
                    <>
                        <button
                            className="position-fixed  text-white p-3 "
                            style={{
                                left: 20,
                                backgroundColor: "#d12420",
                                bottom: 20,
                                zIndex: 100,
                                borderRadius: '50%',
                                border: '2px solid white',
                                width: '60px',
                                height: '60px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease, background-color 0.3s ease',
                            }}
                            onClick={openWhatsApp}

                        >
                            <FaWhatsapp size={25} />
                        </button>

                        <button
                            className="position-fixed text-white p-3 "
                            style={{
                                right: 20,
                                bottom: 20,
                                zIndex: 100,
                                backgroundColor: "#d12420",
                                borderRadius: '50%',
                                border: '2px solid white',
                                width: '60px',
                                height: '60px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease, background-color 0.3s ease',
                            }}
                            onClick={scrollToTop}
                        >
                            <IoIosArrowUp size={25} />
                        </button>
                    </>
                )
            }

        </>
    );
}

export default FixedButtons;

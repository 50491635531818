import React from "react";
import BlogSidebar from "./BlogSidebar";
import PostComment from "./PostComment";
import { useQuery } from "@tanstack/react-query";
import { getBlogsDetails, getCategoriesData } from "../http/httpRequest";
import { useParams } from "react-router-dom";
import { h1 } from "framer-motion/client";
import useLanguage from "../customHook/useLanguage";
import LoadingLayout from "../loadingLayout/LoadingLayout";


const BlogsDetails = () => {
  const { slugs } = useParams();

  const { data: blogDetailsData, isLoading: blogDetailsDatasLoading } = useQuery({
    queryKey: ["get-category", slugs],
    queryFn: () => getBlogsDetails(slugs),
    gcTime: Infinity,
    staleTime: 0,
  });

  const languageState = useLanguage();

  const { data: categoriesData, isLoading: categoriesDataIsLoading } = useQuery({
    queryKey: ["get-category"],
    queryFn: getCategoriesData,
    gcTime: Infinity,
    staleTime: 0,
  });

  if (categoriesDataIsLoading && blogDetailsDatasLoading) return <LoadingLayout/>
  return (
    <>
      <section className="blogs-details-content my-3 my-lg-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="border border-1 rounded-4 p-3">
                {
                  blogDetailsData?.data && (
                    <>
                      <h5>{blogDetailsData?.data[languageState]?.blog_title}</h5>
                      <div>
                        <img
                          src={blogDetailsData?.data[languageState]?.blog_detailphotopath}
                          alt={blogDetailsData?.data[languageState]?.blog_title}
                          className="img-fluid mx-auto"
                          style={{ objectFit: "contain", maxHeight: "100%" }}
                        />
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <div className="d-flex align-items-center gap-2">
                          <i className="bx bx-user txt-theme fs-5" />
                          <p className="txt-theme mb-0">{blogDetailsData?.data[languageState]?.blog_author_name}</p>
                        </div>

                        <div className="d-flex align-items-center gap-2">
                          <i className="bx bx-calendar txt-theme fs-5" />
                          <p className="txt-theme mb-0">{blogDetailsData?.data[languageState]?.blog_date}</p>
                        </div>
                      </div>
                      <div className="mt-3">
                      <p dangerouslySetInnerHTML={{ __html: blogDetailsData?.data[languageState]?.blog_description }} />
                      </div>
                      
                    </>
                  )
                }
              </div>
              {/* <PostComment /> */}
            </div>
            <div className="col-md-4">
              <div>
                <BlogSidebar categoriesData={categoriesData?.data && categoriesData?.data} languageState={languageState}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogsDetails;

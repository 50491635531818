import React from "react";
import { Badge, Breadcrumb, Card, Col, Container, Row } from "react-bootstrap";
import BreadCrumb from "../BreadCrumb";
import SwiperComponent from "./Awards";

import { useTranslation } from "react-i18next";

const AboutDetail = () => {
  const { t } = useTranslation("about");
  const vision = {
    backgroundImage: "url('/vision.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  const mission = {
    backgroundImage: "url('/mission.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  const establishment = {
    backgroundImage: "url('/empowerment.png')",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  const empowerment = {
    backgroundImage: "url('/establishment.png')",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <>
      <section className="about-content-details">
        {/* <BreadCrumb /> */}

        <div className="container mt-3 mt-lg-5">
          <div id="about-patsanstha">
            <div className="text-center mb-3 my-lg-4">
              <h2 className="fw-normal  d-inline-block position-relative custom-heading">
                {t("about")} <span className="head-sub-text">{t("patsanstha")}</span>
              </h2>
            </div>

            <div className="row justify-content-between">
              <div
                className="col-md-6 col-lg-7"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <div className="">
                  <h4 className="txt-footer fw-semibold">
                    {t("about_heading")}
                  </h4>
                  <p className="">
                   {t("about_details_desc_one")}
                  </p>
                  {/* <p className="">
                    {t("about_details_desc_two")}
                  </p>
                  <p className="">
                    {t("about_details_desc_three")}
                  </p> */}
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <div className="outer-frame-border position-relative text-center">
                  <img
                    src="/final_building_img.jpg"
                    alt="main_building"
                    className="iso_certificate p-32px w-100"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="py-4">
            <div className="text-center mb-3 my-lg-4">
              <h2 className="fw-normal mb-3 d-inline-block position-relative custom-heading">
                <span className="head-sub-text">
                  {t("about_motive_title")}
                </span>
              </h2>
              <h5 className="txt-footer">
                {t("about_motive_sub_title")}
              </h5>
            </div>
            <div
              className="row aos-init aos-animate"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <div className="col-md-6">
                <ul className="check-list-arrow position-relative list-unstyled">
                  <li>
                    {t("motive_list_one")}
                  </li>
                  <li>{t("motive_list_two")}</li>
                  <li>{t("motive_list_three")}</li>
                  <li>
                  {t("motive_list_four")}
                  </li>
                  <li>
                  {t("motive_list_five")}
                  </li>
                  <li>{t("motive_list_six")}</li>
                  <li>
                  {t("motive_list_seven")}
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="check-list-arrow position-relative list-unstyled">
                  <li>{t("motive_list_eight")}</li>
                  <li>{t("motive_list_nine")}</li>
                  <li>
                  {t("motive_list_ten")}
                  </li>
                  <li>
                  {t("motive_list_eleven")}
                  </li>
                  <li>
                  {t("motive_list_twelve")}
                  </li>
                  <li>
                  {t("motive_list_thirteen")}
                  </li>
                  <li>{t("motive_list_fourteen")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container py-3" style={empowerment}>
            <div className="row justify-content-end">
              <div className="text-center">
                <h2 className="fw-normal mb-3 d-inline-block position-relative custom-heading">
                  <span className="head-sub-text">
                    {t("about_established_title")}
                  </span>
                </h2>
              </div>
              <div className="col-lg-9 ">
                <div className="vlog-content shadow">
                  <p>
                    {t("established_desc_one")}
                  </p>
                  <p>
                    {t("established_desc_two")}
                  </p>
                  <p>
                    {t("established_desc_three")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container py-3" style={establishment}>
            <div className="row ">
              <div className="text-center">
                <h2 className="fw-normal mb-3 d-inline-block position-relative custom-heading">
                  <span className="head-sub-text">
                   {t('about_merging_title')}
                  </span>
                </h2>
              </div>
              <div className="col-lg-9">
                <div className="vlog-content shadow">
                  <p>
                    {t("merging_desc_one")}
                  </p>
                  <p>
                  {t("merging_desc_two")}
                  </p>
                  <p>
                  {t("merging_desc_three")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container py-3">
            <div className="text-center">
              <h2 className="fw-normal mb-3 d-inline-block position-relative custom-heading">
                <span className="head-sub-text">{t("about_women_title")}</span>
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <img src="/women_resize.jpg" alt="slider-m-5" className="w-100" />
              </div>
              <div className="col-lg-8 vlog-content shadow">
                <p>
                {t("women_desc_one")}
                </p>
                <p>
                {t("women_desc_two")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-3" id="iso ">
          <div className="text-center mb-3 my-lg-4">
            <h2 className="fw-normal  d-inline-block position-relative custom-heading">
              <span className="head-sub-text">{t("about_iso_title")}</span>
            </h2>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-lg-7"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <h4 className="txt-footer fw-semibold">
                {t("about_iso_sub_title")}
              </h4>
              <p>
                {t("iso_desc_one")}
              </p>
              <p>
              {t("iso_desc_two")}
              </p>
              <p className="fw-bold">
              {t("iso_desc_three")}
              </p>
            </div>
            <div
              className="col-md-6 col-lg-5"
              data-aos="fade-left"
              data-aos-duration="1500"
              id=""
            >
              <div className="outer-frame-border position-relative text-center">
                <img
                  src="/ios-certificate.png"
                  alt="iso_certificate"
                  className="iso_certificate "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="py-5"
            data-aos="fade-up"
            data-aos-duration="1500"
            id="history"
          >
            <div className="text-center mb-3 my-lg-4">
              <h2 className="fw-normal  d-inline-block position-relative custom-heading">
                <span className="head-sub-text">{t('about_history_title')}</span>
              </h2>
            </div>
            <p className="">
              {t("history_desc")}
            </p>
          </div>
          <div
            className="row mt-3 my-lg-5 justify-content-around"
            id="vision-mission"
          >
            <div
              className="col-md-6 col-lg-5 mb-3"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div
                className="rounded-4 p-32px shadow bg-vision-mission h-100 position-relative z-3"
                style={vision}
              >
                <img
                  src="/circle-shape.png"
                  className="history-animated vision-animated"
                  alt="circle-shape"
                />
                <div className="text-center mb-3">
                  <h2 className="fw-normal  d-inline-block position-relative ">
                    <span className="text-white fw-semibold">{t("about_vision_title")}</span>
                  </h2>
                </div>
                <p className="text-white">
                  {t("vision_desc")}
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-5 mb-3"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div
                className="rounded-4 p-32px shadow bg-vision-mission h-100 position-relative z-3"
                style={mission}
              >
                <img
                  src="/circle-shape.png"
                  className="history-animated mission-animated"
                  alt="circle-shape"
                />
                <div className="text-center mb-3">
                  <h2 className="fw-normal  d-inline-block position-relative">
                    <span className="text-white fw-semibold">{t("about_mision_title")}</span>
                  </h2>
                </div>
                <ul className="check-list-arrow position-relative list-unstyled">
                  <li className="text-white">
                    {t("mision_list_one")}
                  </li>
                  <li className="text-white">
                  {t("mision_list_two")}
                  </li>
                  <li className="text-white">
                  {t("mision_list_three")}
                  </li>
                  <li className="text-white">
                  {t("mision_list_four")}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="mt-5 awards-achivments-section" id="awards">
            <div className="text-center mb-3 my-lg-4">
              <h2 className="fw-normal  d-inline-block position-relative custom-heading">
                {t("about_award_title")}<span className="head-sub-text">  {t("about_award_recognitions")} </span>
              </h2>
            </div>
            <SwiperComponent />
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutDetail;

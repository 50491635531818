import React from 'react'
import "./why_us.css";
import useLanguage from '../customHook/useLanguage';
import { useTranslation } from 'react-i18next';

function Why_us() {
    const why_usData = [
        {
          english: {
            id: 1,
            image: "why_us_1.png",
            title: "The organization which is successfully working in rural areas and drought.",
            description: "",
            dataDuration: "1000",
          },
          marathi: {
            id: 1,
            image: "why_us_1.png",
            title: "ज्या संस्थेने ग्रामीण भागात आणि दुष्काळी भागात यशस्वीरित्या काम केले आहे.",
            description: "",
            dataDuration: "1000",
          },
        },
        {
          english: {
            id: 2,
            image: "why_us_2.png",
            title: "From the foundation of this institution till today class 'A'.",
            description: "",
            dataDuration: "1000",
          },
          marathi: {
            id: 2,
            image: "why_us_2.png",
            title: "या संस्थेच्या स्थापना पासून आजपर्यंत वर्ग 'अ'.",
            description: "",
            dataDuration: "1000",
          },
        },
        {
          english: {
            id: 3,
            image: "why_us_3.2.png",
            title: "The institution which is struggling to heighten social, economic, ethical, educational, and moral level.",
            description: "",
            dataDuration: "1500",
          },
          marathi: {
            id: 3,
            image: "why_us_3.2.png",
            title: "ज्या संस्थेने सामाजिक, आर्थिक, नैतिक, शैक्षणिक आणि नैतिक पातळीला उंचवण्याचा संघर्ष केला आहे.",
            description: "",
            dataDuration: "1500",
          },
        },
        {
          english: {
            id: 4,
            image: "why_us_4.png",
            title: "Organization which is involving in various social activities and sports.",
            description: "We simplify interest rates and how they impact your finances...",
            dataDuration: "2000",
          },
          marathi: {
            id: 4,
            image: "why_us_4.png",
            title: "संस्था जी विविध सामाजिक कार्यांमध्ये आणि क्रीडांमध्ये सहभागी आहे.",
            description: "आम्ही व्याज दर आणि ते तुमच्या वित्तावर कसा प्रभाव टाकतात हे सोपे करतो...",
            dataDuration: "2000",
          },
        },
        {
          english: {
            id: 5,
            image: "why_us_5.2.png",
            title: "The organization which supply subsidy for domestic business, self-employment and women self help group.",
            description: "",
            dataDuration: "1000",
          },
          marathi: {
            id: 5,
            image: "why_us_5.2.png",
            title: "संस्था जी घरगुती व्यवसाय, स्व-रोजगार आणि महिला स्वयं सहायता गटांसाठी अनुदान पुरवते.",
            description: "",
            dataDuration: "1000",
          },
        },
        {
          english: {
            id: 6,
            image: "why_us_6.1.png",
            title: "The first number organization of Koregaon tehsil which setups big capital and has main office in rural area.",
            description: "",
            dataDuration: "1000",
          },
          marathi: {
            id: 6,
            image: "why_us_6.1.png",
            title: "कोरेगाव तहसीलची पहिली क्रमांकाची संस्था जी मोठ्या भांडवलाची स्थापना करते आणि ग्रामीण भागात मुख्य कार्यालय आहे.",
            description: "",
            dataDuration: "1000",
          },
        },
        {
          english: {
            id: 7,
            image: "why_us_7.png",
            title: "Organization setups highest profits from investment of available funds.",
            description: "",
            dataDuration: "1500",
          },
          marathi: {
            id: 7,
            image: "why_us_7.png",
            title: "संस्था उपलब्ध निधीच्या गुंतवणुकीवरून उच्चतम नफा निर्माण करते.",
            description: "",
            dataDuration: "1500",
          },
        },
        {
          english: {
            id: 8,
            image: "why_us_8.png",
            title: "The organization which has started 46 branches and 6 departments in all west Maharashtra state.",
            description: "",
            dataDuration: "2000",
          },
          marathi: {
            id: 8,
            image: "why_us_8.png",
            title: "संस्था जी पश्चिम महाराष्ट्र राज्यात ४६ शाखा आणि ६ विभाग सुरू करते.",
            description: "",
            dataDuration: "2000",
          },
        },
        {
          english: {
            id: 9,
            image: "why_us_9.png",
            title: "The organization helps customers from Sunday to Friday from 9am to 6pm in a week.",
            description: "",
            dataDuration: "2000",
          },
          marathi: {
            id: 9,
            image: "why_us_9.png",
            title: "संस्था जी ग्राहकांना रविवार ते शुक्रवार, सकाळी ९ ते सायं ६ पर्यंत मदत करते.",
            description: "",
            dataDuration: "2000",
          },
        },
        {
          english: {
            id: 10,
            image: "why_us_10.png",
            title: "As per government, this organization is 1st to complete government norms",
            description: "",
            dataDuration: "2000",
          },
          marathi: {
            id: 10,
            image: "why_us_10.png",
            title: "सरकारानुसार, ही संस्था सरकारच्या नियमांचे पालन करणारी पहिली संस्था आहे.",
            description: "",
            dataDuration: "2000",
          },
        },
        {
          english: {
            id: 11,
            image: "why_us_11.png",
            title: "The organization which provides facility of safe deposit lockers in rural area.",
            description: "",
            dataDuration: "2000",
          },
          marathi: {
            id: 11,
            image: "why_us_11.png",
            title: "संस्था जी ग्रामीण भागात सुरक्षित ठेवीचे लॉकर सुविधा पुरवते.",
            description: "",
            dataDuration: "2000",
          },
        },
        {
          english: {
            id: 12,
            image: "why_us_12.png",
            title: "The organization which provides atpar D.D facility at any place across all over the India.",
            description: "",
            dataDuration: "2000",
          },
          marathi: {
            id: 12,
            image: "why_us_12.png",
            title: "संस्था जी भारतभर कोणत्याही ठिकाणी अ‍ॅटपार डी.डी. सुविधा पुरवते.",
            description: "",
            dataDuration: "2000",
          },
        },
      ];
      const { t } = useTranslation();
      const languageState = useLanguage();
      
    return (
        <div className="container">
            <div className="text-center mb-3 my-lg-4">
                <h2 className="fw-normal d-inline-block position-relative custom-heading">
                   {t("why")} <span className="head-sub-text">{t("us")}</span>
                </h2>
            </div>

            {/* Card 1 */}
            <article className="why-us-card">
                <div className="why-us-card-list"
                >
                    {
                        why_usData?.map((data, index) => {
                            return (
                                <>
                                    <div className='why-us-border-card'
                                     data-aos="fade-right"
                                     data-aos-duration={1000}
                                     key={index}
                                    >
                                        <div className="why-us-card-image">
                                            <img
                                                src={data[languageState].image}
                                                alt={data[languageState].title}
                                            />
                                        </div>
                                        <div className="why-us-card-header">
                                            <p >{data[languageState].title}</p>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </article>
        </div>

    )
}

export default Why_us;
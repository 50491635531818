import React, { useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "bootstrap/dist/css/bootstrap.min.css";
import DepositeRate from "./DepositeRate";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import useLanguage from "../customHook/useLanguage";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);
const InterestRate = () => {
  const [loanAmount, setLoanAmount] = useState(50000); // Default loan amount
  const [interestRate, setInterestRate] = useState(5); // Default interest rate
  const [years, setYears] = useState(5); // Default loan tenure in years

  const data = [
    {
      english: {
        loan_type: "Home Loan",
        interest_rate: "14%",
        loan_limit: "75 Lakhs",
        time_period: "180 Months",
      },
      marathi: {
        loan_type: "गृह कर्ज",
        interest_rate: "14%",
        loan_limit: "75 लाख",
        time_period: "180 महिने",
      },
    },
    {
      english: {
        loan_type: "Home Loan for Mumbai and Pune area",
        interest_rate: "13% 14%",
        loan_limit: "75 Lakhs",
        time_period: "180 Months",
      },
      marathi: {
        loan_type: "मुंबई आणि पुणे क्षेत्रासाठी गृह कर्ज",
        interest_rate: "13% 14%",
        loan_limit: "75 लाख",
        time_period: "180 महिने",
      },
    },
    {
      english: {
        loan_type: "Business Loan",
        interest_rate: "14%",
        loan_limit: "75 Lakhs",
        time_period: "108 Months",
      },
      marathi: {
        loan_type: "व्यवसाय कर्ज",
        interest_rate: "14%",
        loan_limit: "75 लाख",
        time_period: "108 महिने",
      },
    },
    {
      english: {
        loan_type: "Merchant friend loan",
        interest_rate: "14%",
        loan_limit: "5 Lakhs",
        time_period: "84 Months",
      },
      marathi: {
        loan_type: "व्यापारी मित्र कर्ज",
        interest_rate: "14%",
        loan_limit: "5 लाख",
        time_period: "84 महिने",
      },
    },
    {
      english: {
        loan_type: "Cash Credit Loan",
        interest_rate: "14%",
        loan_limit: "75 Lakhs",
        time_period: "84 Months",
      },
      marathi: {
        loan_type: "कॅश क्रेडिट कर्ज",
        interest_rate: "१४%",
        loan_limit: "75 लाख",
        time_period: "84 महिने",
      },
    },
    {
      english: {
        loan_type: "Loan Against FD and Deposit Cash Credit",
        interest_rate: "Interest rates of 2% deposit",
        loan_limit: "80% of the amount of deposit",
        time_period: "-",
      },
      marathi: {
        loan_type: "एफडी आणि ठेव याविरुद्ध कर्ज",
        interest_rate: "2% ठेवीचे व्याज दर",
        loan_limit: "ठेवीच्या रकमेच्या 80% ",
        time_period: "-",
      },
    },
    {
      english: {
        loan_type: "Gold Loan",
        interest_rate: "12%",
        loan_limit: "25 Lakhs",
        time_period: "12 Months",
      },
      marathi: {
        loan_type: "सोन्याचे कर्ज",
        interest_rate: "12%",
        loan_limit: "25 लाख",
        time_period: "12 महिने",
      },
    },
    {
      english: {
        loan_type: "Alpa Mudat Gold Loan",
        interest_rate: "13%",
        loan_limit: "25 Lakhs",
        time_period: "6 Months",
      },
      marathi: {
        loan_type: "अल्प मुदत सोन्याचे कर्ज",
        interest_rate: "13%",
        loan_limit: "25 लाख",
        time_period: "6 महिने",
      },
    },
    {
      english: {
        loan_type: "Gold Purchase Loan",
        interest_rate: "12%",
        loan_limit: "25 Lakhs",
        time_period: "1 To 5 Years",
      },
      marathi: {
        loan_type: "सोन्याची खरेदी कर्ज",
        interest_rate: "12%",
        loan_limit: "25 लाख",
        time_period: "1 ते 5 वर्षे",
      },
    },
    {
      english: {
        loan_type: "Suvarna Rokhpat Gold Loan",
        interest_rate: "13%",
        loan_limit: "25 Lakhs",
        time_period: "12 Months",
      },
      marathi: {
        loan_type: "सुवर्ण रोखपत सोन्याचे कर्ज",
        interest_rate: "13%",
        loan_limit: "25 लाख",
        time_period: "12 महिने",
      },
    },
    {
      english: {
        loan_type: "Saubhagya Alankar Kharedi Loan",
        interest_rate: "10%",
        loan_limit: "1 Lakhs",
        time_period: "36 Months",
      },
      marathi: {
        loan_type: "सौभाग्य अलंकार खरेदी कर्ज",
        interest_rate: "10%",
        loan_limit: "1 लाख",
        time_period: "36 महिने",
      },
    },
    {
      english: {
        loan_type: "Business Gold Loan No. 1",
        interest_rate: "13.5%",
        loan_limit: "25 Lakhs",
        time_period: "12 Months",
      },
      marathi: {
        loan_type: "व्यवसाय सोन्याचे कर्ज क्र. १",
        interest_rate: "13.5%",
        loan_limit: "25 लाख",
        time_period: "12 महिने",
      },
    },
    {
      english: {
        loan_type: "Business Gold Loan No. 2",
        interest_rate: "11%",
        loan_limit: "25 Lakhs",
        time_period: "12 Months",
      },
      marathi: {
        loan_type: "व्यवसाय सोन्याचे कर्ज क्र. २",
        interest_rate: "11%",
        loan_limit: "25 लाख",
        time_period: "12 महिने",
      },
    },
    {
      english: {
        loan_type: "Business Gold Loan No. 3",
        interest_rate: "12%",
        loan_limit: "25 Lakhs",
        time_period: "12 Months",
      },
      marathi: {
        loan_type: "व्यवसाय सोन्याचे कर्ज क्र. ३",
        interest_rate: "12%",
        loan_limit: "25 लाख",
        time_period: "12 महिने",
      },
    },
    {
      english: {
        loan_type: "Guaranteer Loan",
        interest_rate: "15%",
        loan_limit: "1.50 Lakhs",
        time_period: "60 Months",
      },
      marathi: {
        loan_type: "जामीनकी कर्ज",
        interest_rate: "15%",
        loan_limit: "1.50 लाख",
        time_period: "60 महिने",
      },
    },
    {
      english: {
        loan_type: "Small Scale Loan",
        interest_rate: "14%",
        loan_limit: "50,000",
        time_period: "60 Months",
      },
      marathi: {
        loan_type: "लघु उद्योग कर्ज",
        interest_rate: "14%",
        loan_limit: "50,000",
        time_period: "60 महिने",
      },
    },
    {
      english: {
        loan_type: "Mahila Bachat Gat Loan",
        interest_rate: "10%",
        loan_limit: "---",
        time_period: "12 Months",
      },
      marathi: {
        loan_type: "महिला बचत गट कर्ज",
        interest_rate: "10%",
        loan_limit: "---",
        time_period: "12 महिने",
      },
    },
    {
      english: {
        loan_type: "Mahila Sabalikaran Loan",
        interest_rate: "12% ",
        loan_limit: "25,000",
        time_period: "24 Months",
      },
      marathi: {
        loan_type: "महिला सबलीकरण कर्ज",
        interest_rate: "12% ",
        loan_limit: "25,000",
        time_period: "२४ महिने",
      },
    },
    {
      english: {
        loan_type: "Mahila Swayam Rojgar Loan",
        interest_rate: "15%",
        loan_limit: "10,000/- To 20,000/-",
        time_period: "18 To 30 Months",
      },
      marathi: {
        loan_type: "महिला स्वयं रोजगार कर्ज",
        interest_rate: "15%",
        loan_limit: "10,000/- To 20,000/-",
        time_period: "18 ते 30 महिने",
      },
    },
    {
      english: {
        loan_type: "Vehicle Loan - New Vehicle",
        interest_rate: "13.5%",
        loan_limit: "75% of the cost of the vehicle",
        time_period: "60 to 84 Months",
      },
      marathi: {
        loan_type: "वाहन कर्ज - नवीन वाहन",
        interest_rate: "13.5%",
        loan_limit: "वाहनाच्या किमतीच्या ७५%",
        time_period: "60 ते 84 महिने",
      },
    },
    {
      english: {
        loan_type: "Vehicle Loan - Old Vehicle",
        interest_rate: "14%",
        loan_limit: "50% of the cost of the vehicle valuation",
        time_period: "60 Months",
      },
      marathi: {
        loan_type: "वाहन कर्ज - जुने वाहन",
        interest_rate: "14%",
        loan_limit: "वाहनाच्या किमतीच्या मूल्यांकनाच्या ५०%",
        time_period: "60 महिने",
      },
    },
    {
      english: {
        loan_type: "Chhatrapati Easy Car Loan",
        interest_rate: "13%",
        loan_limit: "50 Lakhs",
        time_period: "100 Months",
      },
      marathi: {
        loan_type: "छत्रपती ईझी कार लोन",
        interest_rate: "13%",
        loan_limit: "50 लाख",
        time_period: "100 महिने",
      },
    },
    {
      english: {
        loan_type: "Shasvat Loan",
        interest_rate: "14% ",
        loan_limit: "1 Lakh",
        time_period: "60 Months",
      },
      marathi: {
        loan_type: "शाश्वत कर्ज",
        interest_rate: "14% ",
        loan_limit: "1 लाख",
        time_period: "60 महिने",
      },
    },
    {
      english: {
        loan_type: "Chhatrapati Vidhyavardhani Education Loan",
        interest_rate: "12%",
        loan_limit: "1 Lakh To 3 Lakh",
        time_period: "36 Months",
      },
      marathi: {
        loan_type: "छत्रपती विद्यावर्धनी शिक्षण कर्ज",
        interest_rate: "12%",
        loan_limit: "1 लाख ते 3 लाख",
        time_period: "36 महिने",
      },
    },
    {
      english: {
        loan_type: "Smartphone & Laptop Hire Purchase Loan",
        interest_rate: "13%",
        loan_limit: "1.25 Lakh",
        time_period: "60 Months",
      },
      marathi: {
        loan_type: "स्मार्टफोन आणि लॅपटॉप हायर परचेस कर्ज",
        interest_rate: "13%",
        loan_limit: "1.25 लाख",
        time_period: "60 महिने",
      },
    },
  ];
  

  const languageState = useLanguage();

  const { t } = useTranslation('interest');


  const calculateEMI = () => {
    const monthlyRate = interestRate / 12 / 100;
    const months = years * 12;
    const emi =
      (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, months)) /
      (Math.pow(1 + monthlyRate, months) - 1);
    return emi.toFixed(2);
  };

  const totalInterest = () => {
    const total = calculateEMI() * years * 12 - loanAmount;
    return total.toFixed(2);
  };

  // Chart Data
  const chartData = {
    labels: languageState === "marathi" 
    ? ["मूळ रक्कम", "एकूण व्याज"] 
    : ["Principal Amount", "Total Interest"],
    datasets: [
      {
        label: "Loan Distribution",
        data: [loanAmount, totalInterest()],
        backgroundColor: ["#d12420", "#012B52"],
        borderColor: ["#d12420", "#012B52"],
        borderWidth: 1,
      },
    ],
  };
  
  return (
    <>
      <section className="interest-content">
        <div className="container">
          <div className="loan-calculator mb-4">
            <div className="" id="personal-loan-calculator">
              <div className="row">
                <div className="col-xl-7 col-lg-12">
                  <div className="bg-white shadow-sm rounded-3 p-3">
                    <h4 className="fw-semibold txt-footer">
                      {t('loan_title')}
                    </h4>

                    <div className="">
                      <div className="mt-4">
                        <div className="d-flex align-items-center justify-content-between flex-column flex-md-row gap-3 my-3">
                          <h5 className="mb-0 ">{t("loan_amount")}</h5>
                          <input
                            type="text"
                            className="border-0 outline-none p-2"
                            style={{ backgroundColor: "#eeeeee" }}
                            value={loanAmount}
                          />
                        </div>
                        <input
                          type="range"
                          className="custom-range"
                          min="10000"
                          max="1000000"
                          step="10000"
                          value={loanAmount}
                          onChange={(e) =>
                            setLoanAmount(Number(e.target.value))
                          }
                          style={{
                            background: `linear-gradient(to right, #ff0000 ${((loanAmount - 10000) / (1000000 - 10000)) * 100
                              }%, #ddd ${((loanAmount - 10000) / (1000000 - 10000)) * 100
                              }%)`,
                          }}
                        />
                        <label className="fw-semibold fs-5 mt-2">
                          {" "}
                          ₹{loanAmount}
                        </label>
                      </div>
                    </div>
                    <div className="mt-4 ">
                      <div className="d-flex align-items-center justify-content-between flex-column flex-md-row gap-3 my-3">
                        <h5 className="mb-0 ">{t("interest_rate")}</h5>
                        <input
                          type="text"
                          className="border-0 outline-none p-2"
                          style={{ backgroundColor: "#eeeeee" }}
                          value={interestRate}
                        />
                      </div>

                      <input
                        type="range"
                        className="custom-range"
                        min="1"
                        max="20"
                        step="0.1"
                        value={interestRate}
                        onChange={(e) =>
                          setInterestRate(Number(e.target.value))
                        }
                        style={{
                          background: `linear-gradient(to right, #ff0000 ${((interestRate - 1) / (20 - 1)) * 100
                            }%, #ddd ${((interestRate - 1) / (20 - 1)) * 100}%)`,
                        }}
                      />
                      <label className="fw-semibold fs-5 mt-2">
                        {interestRate}%
                      </label>
                    </div>

                    <div className="mt-4">
                      <div className="d-flex align-items-center justify-content-between flex-column flex-md-row gap-3 my-3">
                        <h5 className="mb-0 ">{t('loan_tenure')}</h5>
                        <input
                          type="text"
                          className="border-0 outline-none p-2"
                          style={{ backgroundColor: "#eeeeee" }}
                          value={years}
                        />
                      </div>

                      <input
                        type="range"
                        className="custom-range"
                        min="1"
                        max="30"
                        step="1"
                        value={years}
                        onChange={(e) => setYears(Number(e.target.value))}
                        style={{
                          background: `linear-gradient(to right, #ff0000 ${((years - 1) / (30 - 1)) * 100
                            }%, #ddd ${((years - 1) / (30 - 1)) * 100}%)`,
                        }}
                      />
                      <label className="fw-semibold fs-5 mt-2">
                        {years} {t("years")}
                      </label>
                    </div>
                    <div className="text-center p-3">
                      <h5 className="d-inline-flex p-2 bg-secondary-theme p-2">
                        {t("monthly_emi")}: ₹{calculateEMI()}
                      </h5>
                    </div>
                    <div className="note mt-4">
                      <p>
                        <strong>{t("note")}</strong> 
                        {t("note_desc")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5">
                  <div
                    className="emi-calculation p-3 bg-white shadow-sm"
                    rounded-3
                  >
                    <div className="text-center  chart-show">
                      <Doughnut data={chartData} />
                    </div>
                    <div className="mt-3 mt-lg-5 text-center">
                      <h3 className="txt-footer">
                        {t("total_interest")} ₹{totalInterest()}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Table striped bordered hover responsive className="table-modern">
            <thead>
              <tr>
                <th>{t("sr_no")}</th>
                <th>{t("loan_type")}</th>
                <th>{t("interest_rate")}</th>
                <th>{t("loan_limit")}</th>
                <th>{t('period')}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row[languageState].loan_type}</td>
                  <td>{row[languageState].interest_rate}</td>
                  <td>{row[languageState].loan_limit}</td>
                  <td>{row[languageState].time_period}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </section>
    </>
  );
};

export default InterestRate;

import React from 'react';
import './Schemes.css';
import useLanguage from '../customHook/useLanguage';
import { useQuery } from '@tanstack/react-query';
import { getSchemeData } from '../http/httpRequest';
import { Link } from 'react-router-dom';
import LoadingLayout from '../loadingLayout/LoadingLayout';
import { useTranslation } from 'react-i18next';
// import LoadingLayout from '../landingPage/loadingLayout/LoadingLayout';

function Schemes() {
    const languageState = useLanguage();
    const  { t } = useTranslation();
    const { data: schemesData, isLoading: schemesDataIsLoading } = useQuery({
        queryKey: ["schemes-data", languageState],
        queryFn: getSchemeData,
        gcTime: Infinity,
        staleTime: 0,
    });

    if(schemesDataIsLoading) return <LoadingLayout/>

    return (
        <div className="container">
            <div className="text-center mb-3 my-lg-4">
                <h2 className="fw-normal d-inline-block position-relative custom-heading">
                    {t("our")} <span className="head-sub-text">{t("schemes")}</span>
                </h2>
            </div>
            <div className="card-list"
                data-aos="fade-up"
                data-aos-duration={1000}
            >
                {
                    schemesData?.data[languageState]?.map((scheme) => {
                        return (
                            <Link to={`/scheme-details/${scheme?.id}`}>
                            <article className="card" key={scheme?.id}>
                                <div className="card-image">
                                    <img
                                        src={scheme?.image}
                                        alt={scheme?.title}
                                    />
                                </div>
                                <div className=' position-absolute scheme-overlay-content w-100 '>
                                   {scheme?.title}
                                </div>
                                
                            </article>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Schemes;

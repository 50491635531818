import React, { useEffect, useState } from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/open-animation.css";
import "./home-style.css";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Home = ({ bannerData, languageState }) => {
  const [screens, setScreens] = useState("web");
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 768) {
      setScreens("web");
    } else if (width >= 576 && width <= 767) {
      setScreens("tablet");
    } else if (width >= 320 && width <= 575) {
      setScreens("mobile");
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const banners = (bannerData && bannerData[languageState]) || [];
    if (banners.length > 0) {
      const id = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
      }, 3000);
      return () => clearInterval(id);
    }
  }, [bannerData, languageState, screens]);

  return (
    <section className="home-section">
      <div className="container-lg px-0">
        <div className="">
          <AutoplaySlider
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={6000}
            animation="openAnimation"
            selected={currentIndex}
            onTransitionRequest={(index) => setCurrentIndex(index)}
          >
            {bannerData &&
              bannerData[languageState]?.map((banner) => {
                let bannerSrc = "";
                if (screens === "mobile" && banner?.banner_mobile_photo) {
                  bannerSrc = banner.banner_mobile_photo;
                } else if (
                  screens === "tablet" &&
                  banner?.banner_tablet_photo
                ) {
                  bannerSrc = banner.banner_tablet_photo;
                } else if (screens === "web" && banner?.banner_web_photo) {
                  bannerSrc = banner.banner_web_photo;
                }

                return bannerSrc ? (
                  <div key={banner.id} data-src={bannerSrc} />
                ) : null;
              })}
          </AutoplaySlider>
        </div>
      </div>
    </section>
  );
};

export default Home;


export const validateApplyForm = ( formData, setErrors) => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required.";
      isValid = false;
    } else if (formData.name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long.";
      isValid = false;
    }

    if (!formData.phoneNo) {
      newErrors.phoneNo = "Phone number is required.";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phoneNo)) {
      newErrors.phoneNo = "Phone number must be exactly 10 digits.";
      isValid = false;
    }
    if (!formData.email) {
        newErrors.phoneNo = "Email is required.";
        isValid = false;
      } else if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
      isValid = false;
    }
    if (!formData.experience) {
        newErrors.experience = "Experience field is required.";
        isValid = false;
      } 
    //   if (!formData.experienceYears) {
    //     newErrors.experience = "Experience years field is required.";
    //     isValid = false;
    //   } 
    //   if (!formData.experienceYears) {
    //     newErrors.experience = "Experience months field is required.";
    //     isValid = false;
    //   } 

      if (!formData.skills) {
        newErrors.skills = "Skills field is required.";
        isValid = false;
      } 

    setErrors(newErrors); 
    return isValid;
  };
 
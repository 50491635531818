import React from "react";
import "animate.css";
import "./sitemap.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SiteMap() {
  const { t } = useTranslation();
  return (
    <div className="container sitemap-content">
      <div className="row">
        <div className="text-center mb-3 my-lg-4">
          <h2 className="fw-normal d-inline-block position-relative custom-heading">
            <span className="head-sub-text">{t("sitemap")}</span>
          </h2>
        </div>
      </div>
      <div className="sitemap">
        <div className="row">
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="section-header">
              <Link to="/" className="section-title">
                {t("home")}
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="section-header">
              <Link  className="section-title">
                {t("about_us")}
              </Link>
            </div>
            <ul className="list-unstyled sub-list">
              <li>
                <Link to="/about/patsanstha">{t("brief_about")}</Link>
              </li>
              <li>
                <Link to="/about/board-directors">{t("board_directors")}</Link>
              </li>
              <li>
                <Link to="/about/development">{t("development")}</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="section-header">
              <Link  className="section-title">
              {t("interest_rates")}
              </Link>
            </div>
            <ul className="list-unstyled sub-list">
              <li>
                <Link to="/interest-rate/loan">{t("loan")}</Link>
              </li>
              <li>
                <Link to="/interest-rate/deposite">{t("deposit")}</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="section-header">
              <Link to="/why-us" className="section-title">
                {t("why_us")}
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="section-header">
              <Link to="/news&events" className="section-title">
                {t("news_and_events")}
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="section-header">
              <Link className="section-title">
                {t("gallery")}
              </Link>
            </div>
            <ul className="list-unstyled sub-list">
              <li>
                <Link to="/gallery/photo">{t("photo_gallery")}</Link>
              </li>
              <li>
                <Link to="/gallery/video">{t("video_gallery")}</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="section-header">
              <Link to="/schemes" className="section-title">
                {t('schemes')}
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="section-header">
              <Link to="/blogs" className="section-title">
                {t("blogs")}
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="section-header">
              <Link to="/career" className="section-title">
              {t("career")}
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="section-header">
              <Link to="/branches" className="section-title">
              {t("branches")}
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 mb-3">
            <div className="section-header">
              <Link to="/newspaper" className="section-title">
              {t("group_news")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteMap;

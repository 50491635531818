import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "../awards.css";

// Import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useTranslation } from "react-i18next";

export default function SwiperComponent() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { t } = useTranslation("about");

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2 pb-3"
      >
        <SwiperSlide>
          <img src="/news-1.png" />
          <div className="position-absolute bottom-0 left-0 p-4 image-overlay-content">
            {t('award_desc_one')}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src="/news-2.png" />
          <div className="position-absolute bottom-0 left-0 p-4 image-overlay-content">
          {t('award_desc_two')}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src="/news-4.png" />
          <div className="position-absolute bottom-0 left-0 p-4 image-overlay-content">
          {t('award_desc_three')}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img src="/Award_1.jpeg" />
          <div className="position-absolute bottom-0 left-0 p-4 image-overlay-content">
          {t('award_desc_four')}
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <img src="/news-2.png" />
          <div className="position-absolute bottom-0 left-0 p-4 image-overlay-content">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam
            tempore atque sint pariatur, perspiciatis consectetur dolores
            aspernatur nihil impedit. Fugit esse tempore dolorem autem
            voluptatum quisquam quibusdam quo ipsam hic.
          </div>
        </SwiperSlide> */}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src="/news-1.png" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/news-2.png" />
        </SwiperSlide>
        {/* <SwiperSlide>
          <img src="/news-3.png" />
        </SwiperSlide> */}
        <SwiperSlide>
          <img src="/news-4.png" />
        </SwiperSlide>
         <SwiperSlide>
          <img src="/Award_1.jpeg" />
        </SwiperSlide>
       
      </Swiper>
    </>
  );
}

export const validateForm = ( formData, setErrors) => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required.";
      isValid = false;
    } else if (formData.name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long.";
      isValid = false;
    }

    if (!formData.phoneNo) {
      newErrors.phoneNo = "Phone number is required.";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phoneNo)) {
      newErrors.phoneNo = "Phone number must be exactly 10 digits.";
      isValid = false;
    }
    if (!formData.email) {
        newErrors.phoneNo = "Email is required.";
        isValid = false;
      } else if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
      isValid = false;
    }
    if (!formData.subject) {
        newErrors.subject = "Subject is required.";
        isValid = false;
      } else if (formData.subject.length < 3) {
        newErrors.subject = "Subject must be at least 3 characters long.";
        isValid = false;
      } 

      if (formData.message.length < 3) {
        newErrors.message = "Message must be at least 3 characters long.";
        isValid = false;
      }

      if (!formData.branch) {
        newErrors.branch = "Select Branch";
        isValid = false;
      }

    setErrors(newErrors); 
    return isValid;
  };

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BlogSidebar from "./BlogSidebar";
import { getBlogsData, getCategoriesData } from "../http/httpRequest";
import { useQuery } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import Pagination from "./pagination/Pagination";
import useLanguage from "../customHook/useLanguage";
import LoadingLayout from "../loadingLayout/LoadingLayout";
import { useTranslation } from "react-i18next";
// import LoadingLayout from "../loadingLayout/LoadingLayout";



const Blogs = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [debounceSearch, setDebounceSearch] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchInputRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);

  const search = searchParams.get("search");
  const page = parseInt(searchParams.get("page") || 1); 
  const limit = parseInt(searchParams.get("limit") || 10);
  const category = searchParams.get("category") || '';

  const languageState = useLanguage();
  const { t } = useTranslation("otherlinks")

  const debouncedSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 500),
    []
  );

  const { data: blogsData, isLoading: blogsDataIsLoading } = useQuery({
    queryKey: ["get-banner", debounceSearch, page, category, languageState],
    queryFn: () => getBlogsData({ debounceSearch, page, limit, category }),
    gcTime: Infinity,
    staleTime: 0,
  });

  const { data: categoriesData, isLoading: categoriesDataIsLoading } = useQuery({
    queryKey: ["get-category", languageState],
    queryFn: getCategoriesData,
    gcTime: Infinity,
    staleTime: 0,
  });


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
    debouncedSearch(value);
    if (value) {
      navigate(`/blogs?search=${encodeURIComponent(value)}&limit=${limit}&page=${page}&category=${category || ''}`);
    }else {
      navigate(`/blogs?page=${page}&limit=${limit}&category=${category || ''}`);
    }
  };

  // const handleOutsideClick = (e) => {
  //   if (searchInputRef.current && !searchInputRef.current.contains(e.target)) {
  //     setSearchKeyword("");
  //     setDebounceSearch("");
  //     navigate(`/blogs`);
  //     // navigate(`/blogs?limit=${limit}&page=1`);
  //   }
  // };

  // useEffect(() => {
  //   if (search) {
  //     setSearchKeyword(search);
  //   }

  //   document.addEventListener("click", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, [search, navigate]);

  const handlePageChange = (page) => {
    if (search) {
      navigate(`/blogs?search=${encodeURIComponent(search)}&page=${page}&limit=${limit}&category=${category || ''}`);
    } else {
      navigate(`/blogs?page=${page}&limit=${limit}&category=${category || ''}`);
    }
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  if(blogsDataIsLoading && categoriesDataIsLoading) return <LoadingLayout/>

  return (
    <>
      {" "}
      <section className="blogs-content">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-8">
              <div className="border border-1 rounded-4 p-3">
                <input
                  type="text"
                  placeholder={t("search_blogs")}
                  className="form-control mb-3"
                  value={searchKeyword}
                  id="search"
                  name="search"
                  onChange={handleSearchChange}
                  ref={searchInputRef}
                />
                <div className="row g-3 align-items-start">
                  { blogsData?.data && 
                    blogsData?.data?.[languageState]?.length > 0 ? (
                      blogsData?.data?.[languageState]?.map((blog) => (
                        <>
                          <div className="col-lg-8 col-md-6 col-12" key={blog.blog_id}>
                            <div>
                              <h5 className="fw-bold pb-2 lh-1 txt-footer">
                                {blog.blog_title}
                              </h5>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center gap-2">
                                <i className="bx bx-user txt-theme fs-5" />
                                <p className="txt-theme mb-0">{blog.blog_author_name}</p>
                              </div>

                              <div className="d-flex align-items-center gap-2">
                                <i className="bx bx-calendar txt-theme fs-5" />
                                <p className="txt-theme mb-0">{blog.blog_date}</p>
                              </div>
                            </div>

                            <div className="my-3">
                              <p className="truncate" dangerouslySetInnerHTML={{ __html: blog.blog_description }} />
                            </div>

                            <Link className="txt-theme fw-semibold" to={`/blogs-details/${blog.blog_slug}`}>
                              {t("read_more")}
                            </Link>
                          </div>
                          <div className="col-lg-4 col-md-6 col-12">
                            <img
                              src={blog.blog_photo}
                              alt={blog.blog_title}
                              className="img-fluid mx-auto"
                              style={{ objectFit: "contain", maxHeight: "100%" }}
                            />
                          </div>
                          <hr className="silo-divider" />
                        </>
                      ))
                    ) : (
                      <div className="">
                        <h5 className="text-center">{t("blogs_avialable")}</h5>
                      </div>
                    )
                  }
                </div>
                <nav className="mt-4" aria-label="Page navigation">
                  <Pagination
                    totalPages={blogsData?.totalPages} 
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </nav>
              </div>
            </div>
            <div className="col-md-4">
              <BlogSidebar categoriesData={categoriesData?.data && categoriesData?.data} languageState={languageState} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;

import React from 'react'
import { Badge, Breadcrumb, Col, Row } from "react-bootstrap";
import BoardDirectorList from './BoardDirectorList';
import { useTranslation } from 'react-i18next';

function BoardDirectors() {
    const { t } = useTranslation("about");
    return (
        <div
            className="container mt-5 mt-lg-3"
            data-aos="fade-up"
            data-aos-duration="1500"
            id="directors"
        >
            <div className="text-center mb-3 my-lg-4">
                <h2 className="fw-normal  d-inline-block position-relative custom-heading">
                    {t("about")} <span className="head-sub-text"> {t("founder")}</span>
                </h2>
            </div>
            <div className="row g-5">
                <div className="col-md-4 col-lg-3 text-center">
                    <div className="d-inline-block director position-relative " >
                        <img src="/chairmen_saheb.jpg" alt="director" className="founder-img" />
                    </div>
                    <h6 className='fw-bold mt-2'>{t("founder_name")}</h6>
                    <h6 className=''>{t("founder_of_patsanstha")}</h6>
                    <h6 className=''>{t('director_of_satara')}</h6>
                </div>
                <div className="col-md-8 col-lg-9 mb-4">
                    <h3>{t("founder_title")}</h3>
                    <div>
                        <p className='mb-4'>
                            {t("founder_desc_one")}
                        </p>
                        <p className='mb-4'>
                            {t("founder_desc_two")}
                        </p>
                    </div>
                    <Row>

                        <div className="horizontal-timeline">
                            <ul className="timeline list-unstyled row justify-content-between">
                                <Col lg="12">
                                    <li className="mb-4">
                                        <div className="px-2 px-md-4">

                                            <p className="text-muted ">
                                                <span className="fw-bold">1) </span>
                                                {t("founder_list_one")}
                                            </p>

                                        </div>
                                    </li>
                                </Col>
                                <Col lg="12">
                                    <li className="mb-4">
                                        <div className="px-2 px-md-4">
                                            <p className="text-muted">
                                                <span className="fw-bold">2) </span>
                                                {t("founder_list_two")}
                                            </p>
                                        </div>
                                    </li>
                                </Col>
                                <Col lg="12">
                                    <li className="mb-4">
                                        <div className="px-2 px-md-4">
                                            <p className="text-muted">
                                                <span className="fw-bold">3) </span>
                                                {t("founder_list_three")}
                                            </p>
                                        </div>
                                    </li>
                                </Col>
                                <Col lg="12">
                                    <li className="mb-4">
                                        <div className="px-2 px-md-4">
                                            <p className="text-muted">
                                                <span className="fw-bold">4) </span>
                                                {t("founder_list_four")}
                                            </p>

                                        </div>
                                    </li>
                                </Col>
                            </ul>
                        </div>

                    </Row>
                </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1500" id="development">
                <BoardDirectorList />
            </div>
        </div>
    )
}

export default BoardDirectors
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    language: "marathi",
}
const languageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        addLanguage : (state, action) => {
            const { language } = action.payload;
            if(language) {
                state.language = language;
            }
        },
        deleteLanguage : (state) => {
            state.language = "marathi";
        }
    }
});

export const { addLanguage, deleteLanguage } = languageSlice.actions;
export default languageSlice.reducer;


import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';

i18n
  .use(HttpApi) // Load translations using HttpApi
  .use(LanguageDetector) // Detect language automatically
  .use(initReactI18next)
  .init({
    lng: 'mr',
    fallbackLng: 'mr', // Default language
    debug: false, // Set to false in production
    interpolation: {
      escapeValue: false, // React already escapes
    },

    // Backend plugin options
    backend: {
      // Load translations from the correct namespace
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    // Define the default namespaces you are using
    ns: ['header', 'about', 'interest', 'contact', 'career', 'otherlinks'], // List all your namespaces here
    defaultNS: 'header', // Set the default namespace
  });

export default i18n;

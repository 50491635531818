import React from "react";
import { Image } from "react-bootstrap";

const Scheme = () => {
  const bgImageScheme = {
    backgroundImage: "url('scheme.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "#fff",
  };
  const schemeAnimated = {
    backgroundImage: "url('scheme-animated.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "#fff",
    minHeight: "250px",
    overflowX:"hidden"
  };

  return (
    <>
      {/* <section
        className="scheme-content p-32px position-relative"
        style={bgImageScheme}
      >
        <div className="container-fluid position-relative z-3 text-center">
          <h3 className="mb-xl-4 mb-3">
            संस्थेच्या ठेव <span className="txt-primary">योजना</span>
          </h3>
          <div className="d-flex justify-content-around align-items-center flex-wrap gap-3">
            <div>
              <Image
                src="scheme-1.png"
                alt="scheme-1"
                className="p-1 bg-white rounded-1 position-relative icon-border-effect"
                height={80}
              />
              <h6 className="fw-bolder mt-3">उत्कर्ष अमृतकलश ठेव</h6>
            </div>
            <div>
              <Image
                src="scheme-4.png"
                alt="scheme-4"
                className="p-1 bg-white rounded-1 position-relative icon-border-effect"
                height={80}
              />
              <h6 className="fw-bolder mt-3">मासिक व्याज ठेव</h6>
            </div>
            <div>
              <Image
                src="scheme-2.png"
                alt="scheme-2"
                className="p-1 bg-white rounded-1 position-relative icon-border-effect"
                height={80}
              />
              <h6 className="fw-bolder mt-3">मनीप्लस ठेव</h6>
            </div>
            <div>
              <Image
                src="scheme-3.png"
                alt="scheme-3"
                className="p-1 bg-white rounded-1 position-relative icon-border-effect"
                height={80}
              />
              <h6 className="fw-bolder mt-3">दैनंदिन ठेव</h6>
            </div>

            <div>
              <Image
                src="scheme-5.png"
                alt="scheme-5"
                className="p-1 bg-white rounded-1 position-relative icon-border-effect"
                height={80}
              />
              <h6 className="fw-bolder mt-3">दामदुप्पट ठेव</h6>
            </div>
            <div>
              <Image
                src="scheme-6.png"
                alt="scheme-6"
                className="p-1 bg-white rounded-1 position-relative icon-border-effect"
                height={80}
              />
              <h6 className="fw-bolder mt-3">दामदीडपट ठेव</h6>
            </div>
          </div>
        </div>
      </section> */}
      <section
        className="scheme-animated p-32px position-relative d-flex align-items-center justify-content-center"
        style={schemeAnimated}
      >
        <div className="text-center position-relative z-3">
          <h3>मोफत सुविधा</h3>
          <h3>मिळणार घरपोच, तेही फक्त एका फोन वर डायल करा 8530030700, 9766599601</h3>
        </div>
        <img
          src="delivery.png"
          alt="Scooter"
          className="img-fluid scooter"
          width="100px"
        ></img>
      </section>
    </>
  );
};

export default Scheme;

import React from "react";
import "./icon_text_buttons.css";
import { IoCalculator } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import { FaRegNewspaper } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const IconTextButtons = () => {
    const {t} = useTranslation();

    return (
        <div className="icon-text-buttons-container">
            <ul>
                <li>
                    <button className="icon-text-button">
                        <span className="icon "><IoCalculator size={25} /></span>
                        <Link to="/interest-rate/loan">
                            <span className="text ">{t("emi_calculator")}</span>
                        </Link>
                    </button>
                </li>
                <li>
                    <button className="icon-text-button">
                        <span className="icon "><IoLocationSharp size={25} /></span>
                        <Link to="/branches">
                            <span className="text ">{t("nearest_branch")}</span>
                        </Link>
                    </button>
                </li>
                <li>
                    <button className="icon-text-button">
                        <span className="icon "><FaRegNewspaper size={25} /></span>
                        <Link to="/news&events">
                            <span className="text ">{t("news_and_events")}</span>
                        </Link>
                    </button>
                </li>
                <li>
                    <button className="icon-text-button">
                        <span className="icon "><FaPhone size={25} /></span>
                        <Link to="/contact-us">
                            <span className="text ">{t("enquiry")}</span>
                        </Link>
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default IconTextButtons;

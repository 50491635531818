import React, { useEffect, useState } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import useLanguage from "../customHook/useLanguage";
import { getGalleyCategories, getGalleyImages } from "../http/httpRequest";
import { useQuery } from "@tanstack/react-query";
import LoadingLayout from "../loadingLayout/LoadingLayout";
import "./portfolioGallery.css";
import { t } from "i18next";

const PortfolioGallery = () => {
    const languageState = useLanguage();
    const [categoryId, setCategoryId] = useState(0);

    const { data: galleryCategories, isLoading: galleryCategoriesIsLoading } = useQuery({
        queryKey: ["get-gallery-categories", languageState],
        queryFn: getGalleyCategories,
        gcTime: Infinity,
        staleTime: 0,
    });

    const { data: galleryImages, isLoading: galleryImagesIsLoading } = useQuery({
        queryKey: ["gallery-images", categoryId],
        queryFn: () => getGalleyImages(categoryId),
        gcTime: Infinity,
        staleTime: 0,
    });


    const handleCategory = (id) => {
        setCategoryId(id);
    }

    useEffect(() => {
        Fancybox.bind("[data-fancybox='gallery']", {
            infinite: true,
            buttons: ["zoom", "close"],
        });
    }, []);

    if (galleryCategoriesIsLoading || galleryImagesIsLoading) return <LoadingLayout />

    return (
        <div className="container">
            <div className="row">
                <div className="text-center mb-3 my-lg-4">
                    <h2 className="fw-normal d-inline-block position-relative custom-heading">
                        {t("photo")} <span className="head-sub-text">{t("gallery")}</span>
                    </h2>
                </div>
            </div>
            <div className="portfolio-menu mt-2 mb-4">
                <ul>
                    <li
                        className={`btn btn-outline-danger ${categoryId === 0 ? "active" : ""}`}
                        data-filter="all"
                        onClick={() => handleCategory(0)}
                    >
                        {t("all")}
                    </li>
                </ul>
                {
                    galleryCategories?.data?.data && galleryCategories?.data?.data[languageState]?.map((category) => {
                        return (
                            <ul key={category.id}>
                                <li className={`btn btn-outline-danger ${categoryId === category.id ? "active" : ""}`} data-filter={category?.id}
                                    onClick={() => handleCategory(category?.id)}
                                >{category?.category_name}</li>
                            </ul>
                        )
                    })
                }
            </div>
            <div className="portfolio-item row">
                {
                    galleryImages?.data?.length > 0 ? (
                        galleryImages?.data?.map((image, index) => {
                            return (
                                <div className="item selfie col-lg-3 col-md-4 col-6 col-sm"
                                    data-aos="flip-left"
                                    data-aos-duration={1000}
                                    key={index}
                                >
                                    <a href={image} data-fancybox="gallery" >
                                        <img className="portfolio-image" src={image} alt={index} />
                                    </a>
                                </div>
                            )
                        })
                    ) : (
                        <div>
                            <h4 className="d-flex justify-content-center">Images Data Not found..!</h4>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default PortfolioGallery;

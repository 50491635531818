import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const NewsBlog = ( { newsEventsData, languageState} ) => {
  const { t } = useTranslation();

  return (
    <section className="p-32px news-content">
      <div className="text-center mb-3 mb-lg-5" data-aos="fade-right">
        <h6 className="txt-theme">{t("news_and_events")}</h6>
        <h2 className="fw-bold txt-gray-dark">
          {t("our_latest_news")}
        </h2>
      </div>
      <div className="container">
        <div className="row">
          {
            newsEventsData && 
          newsEventsData[languageState]?.slice(0,3)?.map((news, index) => (
            <div className="col-md-6 col-lg-4 mb-3" key={index}>
              <div
                className="h-100 bg-white"
                data-aos="fade-up"
                data-aos-duration={1000}
              >
                <div className="new-cards">
                  <div className="overflow-hidden position-relative">
                    <Link to={`/news&events-details/${news?.id}`}>
                    <img src={news?.news_events_image} alt={news?.news_events_title} className="w-100" style={{
                      height: " 270px"
                    }}/>
                    <div className="p-2 position-absolute bottom-0 left-0 image-overlay-content">
                      <p className="txt-12 mb-0">{news?.news_events_title}</p>
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4 text-center">
          <Link to='/news&events'>
          <button className="download-btn bg-secondary-theme">
            <span>{t("view_more")}</span>
          </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NewsBlog;

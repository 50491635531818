import React, { useState, useRef } from 'react';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import "bootstrap/dist/css/bootstrap.min.css";
import "./contact.css";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { PiBuildingOfficeFill } from "react-icons/pi";
import { FaMobileRetro } from "react-icons/fa6";
import { useMutation } from '@tanstack/react-query';

import { contactUs } from '../http/httpRequest';
import { toast } from 'react-toastify';
import { validateForm } from '../utils/ValidateForm';
import { divisionsData } from '../branches/divisionData';
import useLanguage from '../customHook/useLanguage';
import LoadingLayout from '../loadingLayout/LoadingLayout';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

const containerStyle = {
    width: '100%',
    height: '500px',
    border: '1px solid #d12420',
};

const center = {
    lat: 17.9141667,
    lng: 74.0951111,
};

function Contact() {
    const [zoom, setZoom] = useState(10);
    const [showInfo, setShowInfo] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNo: "",
        subject: "",
        message: "",
        branch: "",
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phoneNo: "",
        subject: "",
        message: "",
        branch: ""
    });

    const [selectedBranch, setSelectedBranch] = useState();

    const languageState = useLanguage();

    const { t } = useTranslation('contact');

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    });

    const handleMapLoad = (map) => {
        map.addListener('zoom_changed', () => {
            setZoom(map.getZoom());
        });
    };

    const handleMarkerClick = () => {
        setShowInfo(true);
    };

    const handleMapClick = (e) => {
        if (showInfo) {
            setShowInfo(false);
        }
    };

    const handleCloseClick = () => {
        setShowInfo(false);
    };

    const branchesData = divisionsData.divisions.flatMap((division) =>
        division.branches.map((branch) => branch.branchName[languageState])
    );

    const {
        mutate: contactMutate,
        isPending
    } = useMutation({
        mutationKey: ["reach-us"],
        mutationFn: contactUs,
        onSuccess: (data) => {
            toast.success(data?.message);
            setFormData({
                name: "",
                email: "",
                phoneNo: "",
                subject: "",
                message: "",
                branch: "",
            });
        },
        onError: (error) => {
            toast.error(error.message || "Something went wrong");
        },
    });

    const handleOnChange = (e) => {
        setErrors({
            name: "",
            email: "",
            phoneNo: "",
            subject: "",
            message: "",
            branch: "",
        })
        const { name, value } = e.target;
        setSelectedBranch(value);
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault()
        if (!validateForm(formData, setErrors)) return;

        const dataSend = new FormData();

        dataSend.append("contactus_name", formData.name);
        dataSend.append("contactus_contactno", formData.phoneNo);
        dataSend.append("contactus_email", formData.email);
        dataSend.append("contactus_subject", formData.subject);
        dataSend.append("contactus_branch", selectedBranch);
        dataSend.append("contactus_message", formData.message);

        await contactMutate({ dataSend });
    };

    if (!isLoaded) {
        return <div><LoadingLayout /></div>;
    }

    return (
        <div className="container my-5">
            <div className="text-center mb-3 my-lg-4">
                <h2 className="fw-normal d-inline-block position-relative custom-heading">
                    {t("contact")} <span className="head-sub-text">{t('us')}</span>
                </h2>
            </div>
            <div className="row justify-content-center">
                <div className="d-flex flex-column flex-md-row align-items-stretch">
                    <div className="info text-center">
                        <h2><PiBuildingOfficeFill /> {t("registered_office")}</h2>
                        <h2>{t("office_title")}</h2>
                        <p><FaLocationDot />{t("address")}</p>
                        <p><FaPhone /> 2162-251200</p>
                        <p><FaMobileRetro /> 8530030700, 9766599601</p>
                    </div>
                    <form onSubmit={handleOnSubmit} className="signupForm" name="signupform">
                        <h2 className="text-center">{t("reach_us")}</h2>
                        <ul className="noBullet">
                            <li>
                                <label htmlFor="name">{t("name")} <span className='text-danger fs-4'>*</span></label>
                                <input
                                    type="text"
                                    className="inputFields"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    placeholder={t("enter_name")}
                                    onChange={handleOnChange}
                                    required
                                />
                                {errors.name && <p className="text-danger fs-6">{errors.name}</p>}
                            </li>
                            <li>

                                <label htmlFor="email">{t("email")} <span className='text-danger fs-4'>*</span></label>
                                <input
                                    type="email"
                                    className="inputFields"
                                    id="email"
                                    name="email"
                                    placeholder={t("enter_email")}
                                    value={formData.email}
                                    onChange={handleOnChange}
                                    required
                                />
                                {errors.email && <p className="text-danger fs-6">{errors.email}</p>}
                            </li>
                            <li>
                                <label htmlFor="phoneNo">{t("phone_no")} <span className='text-danger fs-4'>*</span></label>
                                <input
                                    type="number"
                                    className="inputFields"
                                    id="phoneNo"
                                    name="phoneNo"
                                    placeholder={t("enter_phone_no")}
                                    value={formData.phoneNo}
                                    onChange={handleOnChange}
                                    required
                                />
                                {errors.phoneNo && <p className="text-danger fs-6">{errors.phoneNo}</p>}
                            </li>
                            <li>
                                <label htmlFor="subject">{t("subject")} <span className='text-danger fs-4'>*</span></label>
                                <input
                                    type="input"
                                    className="inputFields"
                                    id="subject"
                                    name="subject"
                                    placeholder={t("enter_subject")}
                                    value={formData.subject}
                                    onChange={handleOnChange}
                                    required
                                />
                                {errors.subject && <p className="text-danger fs-6">{errors.subject}</p>}
                            </li>
                            <li>
                                <label htmlFor="branch">{t("branches")}<span className='text-danger fs-4'>*</span></label>
                                <select
                                    className="inputFields"
                                    id="branch"
                                    name="branch"
                                    value={formData.branch}
                                    onChange={handleOnChange}
                                    required
                                >
                                    <option value="">{t("select_branch")}</option>
                                    {branchesData.map((branch) => (
                                        <option key={branch} value={branch}>
                                            {branch}
                                        </option>
                                    ))}
                                    {errors.branch && <p className="text-danger fs-6">{errors.branch}</p>}
                                </select>
                            </li>
                            <li>
                                <label htmlFor="message">{t("message")}</label>
                                <textarea
                                    type="text"
                                    className="inputFields"
                                    id="message"
                                    name="message"
                                    placeholder={t("enter_message")}
                                    value={formData.message}
                                    onChange={handleOnChange}
                                />
                                {errors.message && <p className="text-danger fs-6">{errors.message}</p>}
                            </li>
                            <li id="center-btn" className="text-center">
                                <button type="submit"
                                    id="join-btn"
                                    name="Submit"
                                    alt="Join"
                                    value="Join">
                                    {isPending ? <ClipLoader
                                        color={"#fff"}
                                        size={30}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    /> : t("submit")}
                                </button>
                            </li>
                        </ul>
                    </form>
                </div>
                <div className="col-lg-12 mt-3">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        onLoad={handleMapLoad}
                        onClick={handleMapClick}
                        options={{
                            scrollwheel: true,
                        }}
                    >
                        <Marker
                            position={center}
                            onClick={handleMarkerClick}
                        />
                        {showInfo && (
                            <InfoWindow
                                position={center}
                                onCloseClick={handleCloseClick}
                            >
                                <div style={{ fontSize: '14px' }}>
                                    <strong>{t("office_title")}</strong>
                                    <p> {t("address")}</p>
                                </div>
                            </InfoWindow>
                        )}
                        <a
                            href={`https://www.google.com/maps?q=${center.lat},${center.lng}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                position: 'absolute',
                                bottom: '0px',
                                left: '0px',
                                backgroundColor: '#d12420',
                                color: '#fff',
                                padding: '10px',
                                borderRadius: '5px'
                            }}
                        >
                            {t("view_in_google_maps")}
                        </a>
                    </GoogleMap>
                </div>
            </div>
        </div>
    );
}

export default Contact;

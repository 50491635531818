import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import languageSlice from "./slices/languageSlice";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";


const rootReducers = combineReducers({
    language: languageSlice,
});

const persistConfig = {
    key: "root",
    storage,
    version: 1,
};

const persistedReducers = persistReducer(persistConfig, rootReducers);

const store = configureStore({
    reducer: persistedReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

const persistor = persistStore(store);

export {store, persistor };
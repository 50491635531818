import React, { useEffect } from "react";

import Header from "./component/Header";
import Footer from "./component/Footer";
import LandingPage from "./component/LandingPage";
import AboutDetail from "./component/about/AboutDetail";

import "boxicons";
import "boxicons/css/boxicons.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/pagination";
import AOS from "aos";
import "aos/dist/aos.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Blogs from "./component/blogs/Blogs";
import BlogsDetails from "./component/blogs/BlogsDetails";
import { persistor, store } from "./component/redux/store";
import InterestRate from "./component/intrestRate/InterestRate";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./component/http";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import NewsAndEvents from "./component/news&Events/NewsAndEvents";
import NewsAndEventsDetails from "./component/news&Events/NewsAndEventsDetails";
import PortfolioGallery from "./component/gallery/PortfolioGallery";
import VideoGallery from "./component/gallery/VideoGallery";
import Schemes from "./component/schemes/Schemes";
import Branches from "./component/branches/Branches";
import GroupNews from "./component/group-news/GroupNews";
import Why_us from "./component/why-us/Why_us";
import Contact from "./component/contact/Contact";
import SchemeDetails from "./component/schemes/SchemeDetails";
import Career from "./component/career/Career";
import BoardDirectors from "./component/board_directors/BoardDirectors";
import Development from "./component/development/Development";
import FixedButtons from "./component/fixed_buttons/FixedButtons";
import SiteMap from "./component/sitemap/SiteMap";
import DepositeRate from "./component/intrestRate/DepositeRate";
import IconTextButtons from "./component/fixed-button-icons/IconTextButtons";
import "./i18n";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: "ease", 
      once: true, 
    });
  }, []);

  return (
    <>
      <Provider store={store} >
        <PersistGate persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Header />
              <div className="my-2">
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/about/patsanstha" element={<AboutDetail />} />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/blogs-details/:slugs" element={<BlogsDetails />} />
                  <Route path="/interest-rate/loan" element={<InterestRate />} />
                  <Route path="/interest-rate/deposite" element={<DepositeRate />} />
                  <Route path="/news&events" element={<NewsAndEvents />} />
                  <Route path="/news&events-details/:id" element={<NewsAndEventsDetails />} />
                  <Route path="/gallery/photo" element={<PortfolioGallery />} />
                  <Route path="/gallery/video" element={<VideoGallery/>} />
                  <Route path="/schemes" element={<Schemes />} />
                  <Route path="/branches" element={<Branches />} />
                  <Route path="/newspaper" element={<GroupNews />} />
                  <Route path="/why-us" element={<Why_us />} />
                  <Route path="/contact-us" element={<Contact />} />
                  <Route path="/scheme-details/:schemeId" element={<SchemeDetails />} />
                  <Route path="/career" element={<Career />} />
                  <Route path="/about/board-directors" element={<BoardDirectors />} />
                  <Route path="/about/development" element={<Development />} />
                  <Route path="/sitemap" element={<SiteMap />} />
                </Routes>
              </div>
              <IconTextButtons/>
              <FixedButtons/>
              <Footer />
            </Router>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;

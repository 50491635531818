import React, { useState, useEffect } from 'react';
import useLanguage from '../customHook/useLanguage';
import { useQuery } from '@tanstack/react-query';
import { getGroupNews } from '../http/httpRequest';
import "./GroupNews.css";
import LoadingLayout from '../loadingLayout/LoadingLayout';
import { useTranslation } from 'react-i18next';

function GroupNews() {
    const [groupId, setGroupId] = useState('');
    const languageState = useLanguage();
    const { t } = useTranslation("otherlinks");

    const { data: groupNewsData, isLoading: groupNewsDataIsLoading } = useQuery({
        queryKey: ['group-news', languageState],
        queryFn: () => getGroupNews(),
        gcTime: Infinity,
        staleTime: 0,
    });

    const { data: groupNewsDetails, isLoading: groupNewsDetailsIsLoading } = useQuery({
        queryKey: ['group-news', languageState, groupId],
        queryFn: () => getGroupNews(groupId),
        gcTime: Infinity,
        staleTime: 0,
        enabled: !!groupId,
    });

    useEffect(() => {
        if (groupNewsData?.data && groupNewsData.data[languageState]) {
            setGroupId(groupNewsData.data[languageState][0]?.id || '');
        }
    }, [groupNewsData, languageState]);

    const handleSelectOnchange = (event) => {
        const id = event.target.value;
        setGroupId(id);
    };

    if (groupNewsDataIsLoading || (groupId && groupNewsDetailsIsLoading)) {
        return <LoadingLayout />;
    }

    return (
        <section className="group-news">
            <div className="container mt-3 mt-lg-5">
                <div id="group-news-pdf">
                    <div className="text-center mb-3 my-lg-4">
                        <h2 className="fw-normal d-inline-block position-relative custom-heading">
                            {t("group")} <span className="head-sub-text">{t("news")}</span>
                        </h2>
                    </div>
                    <div className="d-flex justify-content-center align-items-center gap-4">
                        <h4 className="fw-semibold">{t('about_news')}</h4>
                        <select
                            onChange={handleSelectOnchange}
                            className="form-select custom-select"
                            value={groupId}
                            style={{
                                padding: '10px',
                                borderRadius: '8px',
                                border: '1px solid #ddd',
                                fontSize: '16px',
                                width: '250px',
                                textAlign: 'center',
                            }}
                        >
                            <option value="" disabled>
                                {t("selected_month_year")}
                            </option>
                            {groupNewsData?.data &&
                                groupNewsData.data[languageState]?.map((group) => (
                                    <option key={group.id} value={group.id}>
                                        {`${group.groupnews_month}, ${group.groupnews_year}`}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="mt-4">
                        <iframe
                            src={
                                groupId && groupNewsDetails?.data[languageState]?.groupnews_pdf   
                            }
                            style={{
                                width: '100%',
                                height: '600px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                            }}
                            title="Group News PDF"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GroupNews;


import React from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import useLanguage from "../customHook/useLanguage";
import "./sub_board_director.css"

function BoardDirectorList() {
    const { t } = useTranslation("about");
    const data = [
        {
            english: {
                name: "Mr. Dr. Sopan Namdev Chavhan",
                position: "Chairman",
                imageSrc: "/sopanrao.jpeg"
            },
            marathi: {
                name: "श्री. डॉ. सोपान नामदेव चव्हाण",
                position: "चेअरमन",
                imageSrc: "/sopanrao.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Shrikant Prabhakar Shete",
                position: "Vice Chairman",
                imageSrc: "/shrikant.jpeg"
            },
            marathi: {
                name: "श्री. श्रीकांत प्रभाकर शेटे",
                position: "व्हॉ. चेअरमन",
                imageSrc: "/shrikant.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Suresh Gangaram Salunkhe",
                position: "Director",
                imageSrc: "/suresh_bapu.jpeg"
            },
            marathi: {
                name: "श्री. सुरेश गंगाराम साळुंखे",
                position: "संचालक",
                imageSrc: "/suresh_bapu.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Ashok Dinakarrao Lembhe",
                position: "Director",
                imageSrc: "/ashok_d.jpeg"
            },
            marathi: {
                name: "श्री. अशोक दिनकरराव लेंभे",
                position: "संचालक",
                 imageSrc: "/ashok_d.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Raosaheb Sahebrao Lembhe",
                position: "Director",
                 imageSrc: "/raosaheb_lembhe.jpeg"
            },
            marathi: {
                name: "श्री. रावसाहेब साहेबराव लेंभे",
                position: "संचालक",
                 imageSrc: "/raosaheb_lembhe.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Jayvant Gojaba Ghorpade",
                position: "Director",
                 imageSrc: "/ghorpade.jpg"
            },
            marathi: {
                name: "श्री. जयवंत गोजाबा घोरपडे",
                position: "संचालक",
                 imageSrc: "/ghorpade.jpg"
            },
        },
        {
            english: {
                name: "Mr. Bharat Khanderao Salunkhe",
                position: "Director",
                 imageSrc: "/bharatrao.jpeg"
            },
            marathi: {
                name: "श्री. भरत खंडेराव साळुंखे",
                position: "संचालक",
                 imageSrc: "/bharatrao.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Hanamant Baburao Nikam",
                position: "Director",
                 imageSrc: "/hanamant_babu.jpeg"
            },
            marathi: {
                name: "श्री. हणमंत बाबूराव निकम",
                position: "संचालक",
                 imageSrc: "/hanamant_babu.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Tatyaso Nivrutti Dhamal",
                position: "Director",
                 imageSrc: "/Tatyasaheb.jpeg"
            },
            marathi: {
                name: "श्री. तात्यासो निवृत्ती ढमाळ",
                position: "संचालक",
                 imageSrc: "/Tatyasaheb.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Sanjay Sitaram Bhosale",
                position: "Director",
                 imageSrc: "/sanjay_bhosale.jpg"
            },
            marathi: {
                name: "श्री. संजय सिताराम भोसले",
                position: "संचालक",
                 imageSrc: "/sanjay_bhosale.jpg"
            },
        },
        {
            english: {
                name: "Smt. Sulabha Arun Salunkhe",
                position: "Director",
                 imageSrc: "/sulabha_arun.jpeg"
            },
            marathi: {
                name: "श्रीमती सुलभा अरुण साळुंखे",
                position: "संचालिका",
                 imageSrc: "/sulabha_arun.jpeg"
            },
        },
        {
            english: {
                name: "Mrs. Usha Ashokrao Pawar",
                position: "Director",
                 imageSrc: "/usha_ashok.jpeg"
            },
            marathi: {
                name: "सौ. उषा अशोकराव पवार",
                position: "संचालिका",
                 imageSrc: "/usha_ashok.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Gautam Ganapat Kambale",
                position: "Director",
                 imageSrc: "/gautam_k.jpeg"
            },
            marathi: {
                name: "श्री. गौतम गणपत कांबळे",
                position: "संचालक",
                 imageSrc: "/gautam_k.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Satish Kondiba Bichukale",
                position: "Director",
                 imageSrc: "/satish_bichukale.jpeg"
            },
            marathi: {
                name: "श्री. सतीश कोंडिबा बिचुकले",
                position: "संचालक",
                 imageSrc: "/satish_bichukale.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Shashikant Shankar Kumbhar",
                position: "Director",
                 imageSrc: "/shshikant_kumbhar.jpeg"
            },
            marathi: {
                name: "श्री. शशिकांत शंकर कुंभार",
                position: "संचालक",
                 imageSrc: "/shshikant_kumbhar.jpeg"
            },
        },
        {
            english: {
                name: "Mr. Narayan Shankar Sonavane",
                position: "Expert director",
                 imageSrc: "/sonavane.jpg"
            },
            marathi: {
                name: "श्री. नारायण शंकर सोनावणे",
                position: "तज्ञ संचालक",
                 imageSrc: "/sonavane.jpg"
            },
        },
        {
            english: {
                name: "Mr. Satyavan Sadashiv Matkar",
                position: "Expert director",
                 imageSrc: "/matkar.jpeg"
            },
            marathi: {
                name: "श्री. सत्यवान सदाशिव मतकर",
                position: "तज्ञ संचालक",
                 imageSrc: "/matkar.jpeg"
            },
        },
    ];
    const languageState = useLanguage();

    return (
        <div className="mt-5 board-director-list ">
            <div className="text-center mb-3 my-lg-4">
                <h2 className="fw-normal d-inline-block position-relative custom-heading">
                    <span className="head-sub-text">{t("board_of_director_title")}</span>
                </h2>
            </div>
            <div className=" grid-container mb-3">
                   {
                    data.map((board, index)=>{
                        return(
                            <div className="card card-block image-size" key={index}>
                            <img src={board[languageState].imageSrc} alt="Photo of sunset" />
                            <div className="text-content">
                            <h5 className="card-title mx-1">{board[languageState].name}</h5>
                            <p className="mt-1 mb-1 px-2">{board[languageState].position}</p>
                            </div>
                        </div>
                        )
                    } )
                   }
            </div>
        </div>
    );
}

export default BoardDirectorList;
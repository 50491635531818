import React, { useEffect, useState } from "react";
import { Container, DropdownButton, Image, Nav, Navbar } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { addLanguage } from "./redux/slices/languageSlice";
import { useTranslation } from "react-i18next";
import useLanguage from "./customHook/useLanguage";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [language, setLanguage] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const languageState = useLanguage();

  const handleToggle = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const closeMenu = () => {
    setExpanded(false);
  };
  const openLanguage = () => {
    setLanguage(!language);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLanguageClick = (language, lang) => {
    i18n.changeLanguage(lang);
    dispatch(addLanguage({ language }))
  }

  const { pathname } = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [pathname]);

  const handleDropdownHover = (dropdown) => {
    if (window.innerWidth > 768) {
      // Only allow hover behavior on desktop view
      setOpenDropdown(dropdown);
    }
  };
  const handleDropdownClick = (dropdown) => {
    setOpenDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const handleDropdownLeave = () => {
    if (window.innerWidth > 768) {
      setOpenDropdown(null);
    }
  };

  return (
    <section className="">
      <div className="top-header position-relative px-0">
        <div className="container ">
          <div className="row">
            <div className="col-9 col-lg-10">
              <Marquee gradient={false} speed={50} className="text-white">
                {t("running_heading")}
              </Marquee>
            </div>
            <div
              className="col-3 col-lg-2 text-white d-flex align-items-center justify-content-end gap-1 cursor-pointer"
              onClick={openLanguage}
            >
              <i className="bx bx-cog text-white fs-3"></i>
              <h6 className="mb-1 fw-bold">{t("language")}</h6>
              {language && (
                <div
                  className="position-absolute left-0 bg-white text-muted rounded-2 shadow"
                  style={{ top: "2.5rem", width: "100px", zIndex: "99991" }}
                >
                  <ul className="list-unstyled p-0 m-0 ">
                    <li className={`fs-6 px-3 py-2 ${languageState === "english" && "bg-danger text-white"} `} onClick={() => handleLanguageClick("english", "en")}>{t("english")}</li>
                    <li className={`fs-6 px-3 py-2 ${languageState === "marathi" && "bg-danger text-white"}`} onClick={() => handleLanguageClick("marathi", "mr")}>{t("marathi")}</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Navbar
        className={`header ${isSticky ? "sticky" : ""}`}
        variant="dark"
        expand="lg"
        expanded={expanded}
        onToggle={handleToggle}
      >
        <Container className="">
          <Navbar.Brand className="py-0 ">
            <Link to="/">
              <Image
                src="/logo-theme.png"
                alt="logo-theme"
                className="logo-theme"
              />
            </Link>
          </Navbar.Brand>
          <Image
            src="/chhatrapati.png"
            alt="chhatrapati"
            className="chhatrapati d-md-none d-block"
          />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <div className="d-grid flex-column w-90">
            <div className="d-none d-md-flex align-items-center flex-wrap contact-header justify-content-center">
              <Image src="/title.png" alt="title" className="w-md-100" />
            </div>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="top-header menu-header position-relative"
            >
              <Nav className="mx-auto">
                <Link to="/" className={`nav-link ${pathname === "/" ? "active" : ""}`} onClick={closeMenu}>
                  {t('home')}
                </Link>
                <Link
                  className={`dropdown-link nav-link ${pathname.startsWith("/about") ? "active" : ""}`}
                  // onMouseEnter={() => handleDropdownHover("about")}
                  // onMouseLeave={handleDropdownLeave}
                  // onClick={() => handleDropdownClick("about")}
                >
                  {t("about_us")}
                  <ul
                    className={`sub-menu`}
                  >
                    <Link to="/about/patsanstha" className="text-black" onClick={closeMenu}>
                      <li>{t("brief_about")}</li>
                    </Link>
                    <Link to="/about/board-directors" className="text-black" onClick={closeMenu}>
                      <li>{t("board_directors")}</li>
                    </Link>
                    <Link to="/about/development" className="text-black" onClick={closeMenu}>
                      <li>{t("development")}</li>
                    </Link>
                  </ul>
                </Link>
                <Link
                  className={`dropdown-link nav-link ${pathname.startsWith("/interest-rate") ? "active" : ""}`}
                >
                  {t("interest_rates")}
                  <ul className="sub-menu">
                    <Link
                      to="/interest-rate/loan"
                      className="text-black"
                      onClick={closeMenu}
                    >
                      <li className="">
                        {t("loan")}
                      </li>
                    </Link>
                    <Link
                      to="/interest-rate/deposite"
                      className="text-black"
                      onClick={closeMenu}
                    >
                      <li className="">
                        {t("deposit")}
                      </li>
                    </Link>
                  </ul>
                </Link>
                <Link to="/why-us" className={`nav-link ${pathname === "/why-us" ? "active" : ""}`} onClick={closeMenu}>
                  {t("why_us")}
                </Link>

                <Link to="/news&events" className={`nav-link ${pathname === "/news&events" ? "active" : ""}`} onClick={closeMenu}>
                  {t("news_and_events")}
                </Link>

                {/* <Link to="/porfolio-gallery" className={`nav-link ${pathname === "/porfolio-gallery" ? "active" : ""}`} onClick={closeMenu}>
                  {t("gallery")}
                </Link> */}

                <Link  className={`dropdown-link nav-link ${pathname === "/porfolio-gallery" ? "active" : ""}`} >
                  {t("gallery")}
                  <ul className="sub-menu">
                    <Link
                      to="/gallery/photo"
                      className="text-black"
                      onClick={closeMenu}
                    >
                      <li className="">
                        {t("photo_gallery")}
                      </li>
                    </Link>
                    <Link
                      to="/gallery/video"
                      className="text-black"
                      onClick={closeMenu}
                    >
                      <li className="">
                      {t("video_gallery")}
                      </li>
                    </Link>
                  </ul>
                </Link>

                <Link to="/schemes" className={`nav-link ${pathname === "/schemes" ? "active" : ""}`} onClick={closeMenu}>
                  {t("schemes")}
                </Link>

                <Link to="/contact-us" className={`nav-link ${pathname === "/contact-us" ? "active" : ""}`} onClick={closeMenu}>
                  {t("contact_us")}
                </Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </section>
  );
};

export default Header;

import React from 'react';
import { getSchemesDetails } from '../http/httpRequest';
import { useQuery } from '@tanstack/react-query';
import useLanguage from '../customHook/useLanguage';
import { useParams } from 'react-router-dom';
import './SchemeDetails.css'; 
import LoadingLayout from '../loadingLayout/LoadingLayout';

function SchemeDetails() {
    const languageState = useLanguage();
    const { schemeId } = useParams();

    const { data: schemesDetails, isLoading: schemesDetailsIsLoading } = useQuery({
        queryKey: ['schemes-details', languageState, schemeId],
        queryFn: () => getSchemesDetails({ id: schemeId }),
        gcTime: Infinity,
        staleTime: 0,
    });

    if(schemesDetailsIsLoading) return <LoadingLayout/>

    return (
        <div className="container">
            <div>
                {schemesDetails?.data && (
                    <>
                        <div className="text-start scheme-table-div">
                            <h2 className='fancy-title' >
                                {schemesDetails?.data[languageState]?.title}
                            </h2>
                                <div
                                    className='scheme-table'
                                    dangerouslySetInnerHTML={{
                                        __html: schemesDetails?.data[languageState]?.description,
                                    }}
                                />
                   
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default SchemeDetails;

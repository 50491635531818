import { main } from "framer-motion/client";

export const data = [
    // main division
    {
        english: {
            division: "Main Division",
            address: "At.post Pimpode BK Ta. Koregaon Dist. Satara",
            branch: "Main Divisional Office",
            main_office: true,
            contact: " 8600045151",
            mapLocation: "https://www.google.com/maps?q=17.9136307,74.0955334&z=17&hl=en",
        },
        marathi: {
            division: "मुख्य विभाग",
            address: "मु. पो. पिंपोडे  बुद्रुक ता. कोरेगाव जि. सातारा",
            contact: "8600045151",
            branch: "मुख्य विभागीय कार्यालय",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=17.9136307,74.0955334&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Main Division",
            branch: "Main Branch",
            main_office: false,
            address: "At.post  Pimpode BK Ta. Koregaon Dist. Satara.",
            contact: "9766599607",
            mapLocation: "https://www.google.com/maps?q=17.9136307,74.0955334&z=17&hl=en",
        },
        marathi: {
            division: "मुख्य विभाग",
            branch: "मुख्य शाखा",
            main_office: false,
            address: "मुख्य शाखा मु. पो. पिंपोडे  बुद्रुक ता. कोरेगाव जि. सातारा",
            contact: "9766599607",
            mapLocation: "https://www.google.com/maps?q=17.9136307,74.0955334&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Main Division",
            address: "At post -  Pimpode BK Tal - koregaon Dist - satara.",
            branch: "Ganeshmandir",
            main_office: false,
            contact: "9766599613",
            mapLocation: "https://www.google.com/maps?q=17.9136307,74.0955334&z=17&hl=en",
        },
        marathi: {
            division: "मुख्य विभाग",
            branch: "गणेशमंदिर",
            main_office: false,
            address: "मु. पो. - पिंपोडे बुद्रुक ता - कोरेगाव जि - सातारा",
            contact: "9766599613",
            mapLocation: "https://www.google.com/maps?q=17.9136307,74.0955334&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Main Division",
            branch: "S T Stand",
            main_office: false,
            address: "At post -  Pimpode BK Tal - koregaonDist - satara.",
            contact: "9503621081",
            mapLocation: "https://www.google.com/maps?q=17.91190668512612,74.09678008407354&z=17&hl=en",
        },
        marathi: {
            division: "मुख्य विभाग",
            address: "मु. पो. - पिंपोडे बुद्रुक ता - कोरेगाव जि - सातारा",
            branch: "एस.टी स्टॅड",
            main_office: false,
            contact: "9503621081",
            mapLocation: "https://www.google.com/maps?q=17.91190668512612,74.09678008407354&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Main Division",
            address: "At po. Sarkalwadi  Tal. Koregaon Dist. Satara.",
            branch: "Sarkalwadi",
            main_office: false,
            contact: "9766599618",
            mapLocation: "https://www.google.com/maps?q=17.91190668512612,74.09678008407354&z=17&hl=en",
        },
        marathi: {
            division: "मुख्य विभाग",
            address: "मु.पो. सर्कलवाडी  ता. कोरेगाव  जि. सातारा",
            branch: "सर्कलवाडी",
            main_office: false,
            contact: "9766599618",
            mapLocation: "https://www.google.com/maps?q=17.91190668512612,74.09678008407354&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Main Division",
            address: "Opposite Police Station, Vathar Station Tal. Koregaon District. Satara.",
            branch: "Vathar Station",
            main_office: false,
            contact: "9766599614",
            mapLocation: "https://www.google.com/maps?q=17.8890162,74.1367426&z=17&hl=en",
        },
        marathi: {
            division: "मुख्य विभाग",
            address: "पोलिस स्टेशन समोर ता.कोरेगांव जिल्हा. सातारा.",
            branch: "वाठार स्टेशन",
            main_office: false,
            contact: "9766599614",
            mapLocation: "https://www.google.com/maps?q=17.8890162,74.1367426&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Main Division",
            address: "At.Po. Sonake. Tal Koregaon.Dist. Satara. ",
            branch: "Sonake",
            main_office: false,
            contact: "9503056647",
            mapLocation: "https://www.google.com/maps?q=17.9276261,74.0753578&z=17&hl=en",
        },
        marathi: {
            division: "मुख्य विभाग",
            address: "मु.पो. सोनके. ता कोरेगाव.जि. सातारा.",
            branch: "सोनके",
            main_office: false,
            contact: "9503056647",
            mapLocation: "https://www.google.com/maps?q=17.9276261,74.0753578&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Main Division",
            address: "Next to Ram Mandir, At Po Bhadle Tal Koregaon District Satara.",
            branch: "Bhadle",
            main_office: false,
            contact: "9766599620",
            mapLocation: "https://www.google.com/maps?q=17.8467703,74.2163993&z=17&hl=en",
        },
        marathi: {
            division: "मुख्य विभाग",
            branch: "भाडळे",
            main_office: false,
            address: "राम मंदिरा शेजारी, मु पो भाडळे  ता कोरेगाव जि सातारा.",
            contact: "9766599620",
            mapLocation: "https://www.google.com/maps?q=17.8467703,74.2163993&z=17&hl=en",
        },
    },
    // Satara Division
    {
        english: {
            division: "Satara Division",
            address: "105 B Pantancha Got Opposite Jayavijay Talkies Satara Main Road Satara Dist.Satara-415001.",
            contact: "9766599603",
            branch: "Satara Divisional Office",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=17.6879411,74.0035435&z=17&hl=en",
        },
        marathi: {
            division: "सातारा विभाग",
            address: "105 ब पंतांचा गोट जयविजय टाॅकीज समोर सातारा मेन रोड सातारा ता.जि.सातारा -415001.",
            branch: "विभागीय कार्यालय सातारा",
            main_office: true,
            contact: "9766599603",
            mapLocation: "https://www.google.com/maps?q=17.6879411,74.0035435&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Satara Division",
            address: "105 B Pantancha Got Opposite Jayavijay Talkies Satara Main Road Satara Dist.Satara-415001.",
            contact: "9766599623",
            branch: "Satara",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.6879411,74.0035435&z=17&hl=en",
        },
        marathi: {
            division: "सातारा विभाग",
            address: "105 ब पंतांचा गोट जयविजय टाॅकीज समोर सातारा मेन रोड सातारा ता.जि.सातारा -415001.",
            branch: "सातारा",
            main_office: false,
            contact: "9766599623",
            mapLocation: "https://www.google.com/maps?q=17.6879411,74.0035435&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Satara Division",
            address: "Pusegaon Koregaon Road Opposite Bharat Petrol Pump Koregaon 15001.",
            contact: "9766599627",
            branch: "Koregaon",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.7011942,74.1660529&z=17&hl=en",
        },
        marathi: {
            division: "सातारा विभाग",
            address: "पुसेगाव कोरेगांव रोड भारत पेट्रोल पंपासमोर कोरेगांव 15001.",
            branch: "कोरेगांव",
            main_office: false,
            contact: "9766599627",
            mapLocation: "https://www.google.com/maps?q=17.7011942,74.1660529&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Satara Division",
            address: "Rokadeshwar Galli Next to Ganapati Mandir, Raje Heights, Rahimatpur, Koregaon, Dist. Satara-415511.",
            branch: "Rahimatpur",
            main_office: false,
            contact: "9766599628",
            mapLocation: "https://www.google.com/maps?q=17.5921059,74.1998229&z=17&hl=en",
        },
        marathi: {
            division: "सातारा विभाग",
            address: "रोकडेश्वर गल्ली गणपती मंदिरा शेजारी राजे हाईटस् रहिमतपूर ता.कोरेगाव, जि.सातारा -415511.",
            branch: "रहिमतपूर",
            main_office: false,
            contact: "9766599628",
            mapLocation: "https://www.google.com/maps?q=17.5921059,74.1998229&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Satara Division",
            address: "A/P-Saap, Tal-Koregaon,Dist-Satara.",
            branch: "Saap",
            main_office: false,
            contact: "9766599630",
            mapLocation: "https://www.google.com/maps?q=17.5817238,74.2349778&z=17&hl=en",
        },
        marathi: {
            division: "सातारा विभाग",
            address: "पत्ता - मु. पो. साप, तालुका - कोरेगाव, जिल्हा - सातारा. पिनकोड - 415511.",
            branch: "साप",
            main_office: false,
            contact: "9766599630",
            mapLocation: "https://www.google.com/maps?q=17.5817238,74.2349778&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Satara Division",
            address: "Vijay Heights Visava Naka, Zilla Parishad Road, Satara 415002.",
            branch: "Sadarbazar",
            contact: "9503056648",
            main_office: false,
            mapLocation: "https://www.google.com/maps/place/17%C2%B041'10.3%22N+74%C2%B000'54.5%22E/@17.6861962,74.0125704,17z/data=!3m1!4b1!4m4!3m3!8m2!3d17.6861962!4d74.0151453?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
        marathi: {
            division: "सातारा विभाग",
            address: "विजय हाईट्स विसावा नाका, जिल्हा परिषद रोड, सातारा 415002.",
            branch: "सदरबाजार ",
            main_office: false,
            contact: "9503056648",
            mapLocation: "https://www.google.com/maps/place/17%C2%B041'10.3%22N+74%C2%B000'54.5%22E/@17.6861962,74.0125704,17z/data=!3m1!4b1!4m4!3m3!8m2!3d17.6861962!4d74.0151453?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
    },
    {
        english: {
            division: "Satara Division",
            address: "712/1/4 C/ 1A, Race Course Naka, Sambhajinagar, Kolhapur 416012.",
            contact: "9766599626",
            branch: "Kolhapur",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=16.6818363,74.2226525&z=17&hl=en",
        },
        marathi: {
            division: "सातारा विभाग",
            address: "712/1/4 क/ 1अ, रेस कोर्स नाका, संभाजीनगर, कोल्हापूर 416012.",
            contact: "9766599626",
            branch: "कोल्हापूर",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=16.6818363,74.2226525&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Satara Division",
            address: "Manik Manu Chambers Chafal Road, Opposite LIC Office, At Post Umbraj Tal. Karad, Dist. Satara.PIN - 415109.",
            contact: "9503056649",
            branch: "Umbraj ",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.4008083,74.1007789&z=17&hl=en",
        },
        marathi: {
            division: "सातारा विभाग",
            address: "माणिक मनु चेंबर्सचाफळ रोड, एलआय सी ऑफिस समोर, उंब्रज ता. कराड, जि. सातारा.पिन - 415109.",
            branch: "उंब्रज",
            main_office: false,
            contact: "9503056649",
            mapLocation: "https://www.google.com/maps?q=17.4008083,74.1007789&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Satara Division",
            address: "Azad Chowk Koregaon Tal Koregaon.Dist. Satara.",
            branch: "Koregaon",
            main_office: false,
            contact: "8956053274",
            mapLocation: "https://www.google.com/maps?q=17.7006882,74.1598359&z=17&hl=en",
        },
        marathi: {
            division: "सातारा विभाग",
            address: "आझाद चौक कोरेगाव ता कोरेगाव.जि. सातारा.",
            branch: "कोरेगाव",
            main_office: false,
            contact: "8956053274",
            mapLocation: "https://www.google.com/maps?q=17.7006882,74.1598359&z=17&hl=en",
        },
    },

    //Vaduj Division
    {
        english: {
            division: "Vaduj Division",
            address: "Manish Market LIC Office Below Karad Road Vaduj.",
            branch: "Divisional Office",
            main_office: true,
            contact: "7720009179",
            mapLocation: "https://www.google.com/maps?q=17.5916534,74.4499355&z=17&hl=en",
        },
        marathi: {
            division: "वडूज विभाग",
            address: "मनिष मार्केट एल आय सी ऑफिस खाली कराड रोड वडूज.",
            branch: "विभागीय कार्यालय",
            main_office: true,
            contact: "7720009179",
            mapLocation: "https://www.google.com/maps?q=17.5916534,74.4499355&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Vaduj Division",
            address: "Manish Market LIC Office Below Karad Road Vaduj.",
            branch: "Vaduj",
            main_office: false,
            contact: "7722002351",
            mapLocation: "https://www.google.com/maps?q=17.5916534,74.4499355&z=17&hl=en",
        },
        marathi: {
            division: "वडूज विभाग",
            address: "मनिष मार्केट एल आय सी ऑफिस खाली कराड रोड वडूज.",
            branch: "वडूज",
            main_office: false,
            contact: "7722002351",
            mapLocation: "https://www.google.com/maps?q=17.5916534,74.4499355&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Vaduj Division",
            address: "Address - Vishal Complex, Chhatrapati Shivaji Maharaj Chowk, A/P - Pusegaon Dist.Khatav Dist.Satara.",
            contact: "7722002352",
            branch: "Pusegaon",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.7084277,74.3197563&z=17&hl=en",
        },
        marathi: {
            division: "वडूज विभाग",
            address: "पत्ता - विशाल कॉम्प्लेक्स,छात्रपती शिवाजी महाराज चौक,मु.पो.पुसेगाव ता.खटाव जि.सातारा.",
            branch: "पुसेगाव",
            main_office: false,
            contact: "7722002352",
            mapLocation: "https://www.google.com/maps?q=17.7084277,74.3197563&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Vaduj Division",
            address: "New Bus Stand, Aundh Road Pusesawali.",
            branch: "Pusesawali",
            main_office: false,
            contact: "7722002353",
            mapLocation: "https://www.google.com/maps?q=17.4638166,74.3131167&z=17&hl=en",
        },
        marathi: {
            division: "वडूज विभाग",
            address: "नवीन बसस्थानक समोर, औंध रोड पुसेसावळी.",
            branch: "पुसेसावळी",
            main_office: false,
            contact: "7722002353",
            mapLocation: "https://www.google.com/maps?q=17.4638166,74.3131167&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Vaduj Division",
            address: "Dhale mama complex Opposite to S T stand Mhaswad.",
            contact: "7720009177",
            branch: "Mhaswad",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.6352958,74.7845529&z=17&hl=en",
        },
        marathi: {
            division: "वडूज विभाग",
            address: "ढाले मामा कॉम्प्लेक्स एस टी स्टँड समोर म्हसवड.",
            branch: "म्हसवड",
            main_office: false,
            contact: "7720009177",
            mapLocation: "https://www.google.com/maps?q=17.6352958,74.7845529&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Vaduj Division",
            address: "Bus Stop Beside Above Chandan Medical, At. Po. Mayani Pin Code 415102.",
            branch: "Mayani",
            main_office: false,
            contact: "7720009178",
            mapLocation: "https://www.google.com/maps?q=17.4422966,74.5500636&z=17&hl=en",
        },
        marathi: {
            division: "वडूज विभाग",
            address: "बसस्टॉप शेजारी चंदन मेडिकलच्या वरती, मु. पो मायणी  पिन कोड 415102.",
            branch: "मायणी",
            main_office: false,
            contact: "7720009178",
            mapLocation: "https://www.google.com/maps?q=17.4422966,74.5500636&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Vaduj Division",
            address: "Rajhans Plaza ,Phaltan Chouk, Dahivadi Tal -Man ,Dist -Satara Pin 415508.",
            branch: "Dahivadi",
            main_office: false,
            contact: "7720009176",
            mapLocation: "https://www.google.com/maps?q=17.703969955444336,74.54373168945312&z=17&hl=en",
        },
        marathi: {
            division: "वडूज विभाग",
            address: "शाखा दहिवडी, राजहंस प्लाझा, फलटण चौक, दहिवडी ता.माण, जि.सातारा पिन 415508.",
            branch: "दहिवडी",
            main_office: false,
            contact: "7720009176",
            mapLocation: "https://www.google.com/maps?q=17.703969955444336,74.54373168945312&z=17&hl=en",
        },
    },

    //Lonand Division
    {
        english: {
            division: "Lonand Division",
            address: "Branch Baramati (Kasba) Address - Kashivisweshwar Complex Opposite NCP Congress Bhavan Kasba Baramati.",
            contact: "7719096161",
            branch: "Divisional Office",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=18.1442996,74.5678531&z=17&hl=en",
        },
        marathi: {
            division: "लोणंद विभाग",
            address: "शाखा बारामती (कसबा) पत्ता -काशीविश्वेश्वर संकुल राष्ट्रवादी काँग्रेस भवन समोर कसबा बारामती .",
            contact: "7719096161",
            branch: "विभागीय कार्यालय ",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=18.1442996,74.5678531&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Lonand Division",
            address: "Address - Kashivisweshwar Complex Opposite NCP Congress Bhavan Kasba Baramati.",
            branch: "Baramati (Kasba)",
            main_office: false,
            contact: "9766599621",
            mapLocation: "https://www.google.com/maps?q=18.1442996,74.5678531&z=17&hl=en",
        },
        marathi: {
            division: "लोणंद विभाग",
            address: "पत्ता -काशीविश्वेश्वर संकुल राष्ट्रवादी काँग्रेस भवन समोर कसबा बारामती.",
            branch: "बारामती (कसबा)",
            main_office: false,
            contact: "9766599621",
            mapLocation: "https://www.google.com/maps?q=18.1442996,74.5678531&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Lonand Division",
            address: "Lonand, Near Gandhi hospital rijool Glory Lonand, Taluka khandala Dist satara.",
            branch: "Lonand",
            main_office: false,
            contact: "9766599615",
            mapLocation: "https://www.google.com/maps/place/18%C2%B002'09.9%22N+74%C2%B011'09.3%22E/@18.036088,74.183332,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.036088!4d74.1859069?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
        marathi: {
            division: "लोणंद विभाग",
            address: "गांधी हॉस्पिटल जवळ रिजूल ग्लोरी लोणंद, तालुका खंडाळा जि सातारा.",
            branch: "लोणंद",
            main_office: false,
            contact: "9766599615",
            mapLocation: "https://www.google.com/maps/place/18%C2%B002'09.9%22N+74%C2%B011'09.3%22E/@18.036088,74.183332,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.036088!4d74.1859069?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
    },
    {
        english: {
            division: "Lonand Division",
            address: "Girvi Naka, At Po. Phaltan, Ta Phaltan. Dist. Satara.",
            branch: "Phaltan",
            main_office: false,
            contact: "9766599617",
            mapLocation: "https://www.google.com/maps?q=17.9822975,74.432519&z=17&hl=en",
        },
        marathi: {
            division: "लोणंद विभाग",
            address: "गिरवी नाका, मु पो फलटण ,  ता फलटण.जी. सातारा.",
            branch: "फलटण ",
            main_office: false,
            contact: "9766599617",
            mapLocation: "https://www.google.com/maps?q=17.9822975,74.432519&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Lonand Division",
            address: "Chandni Chowk Khamgaon Road Sakharwadi.",
            branch: "Sakharwadi",
            main_office: false,
            contact: "7720009175",
            mapLocation: "https://www.google.com/maps?q=18.0558168,74.3342563&z=17&hl=en",
        },
        marathi: {
            division: "लोणंद विभाग",
            address: "चांदनी चौक खामगाव रोड साखरवाडी.",
            branch: "साखरवाडी",
            main_office: false,
            contact: "7720009175",
            mapLocation: "https://www.google.com/maps?q=18.0558168,74.3342563&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Lonand Division",
            address: "Opposite Chandukaka Saraf, Gandhi Chowk Kacheri Road Baramati.",
            branch: "Baramati City",
            main_office: false,
            contact: "9766263667",
            mapLocation: "https://www.google.com/maps/place/18%C2%B008'55.6%22N+74%C2%B034'17.5%22E/@18.1487753,74.5689515,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.1487753!4d74.5715264?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
        marathi: {
            division: "लोणंद विभाग",
            address: "चंदुकाका सराफ समोर ,गांधी चौक कचेरी रोड बारामती.",
            branch: "बारामती शहर",
            main_office: false,
            contact: "9766263667",
            mapLocation: "https://www.google.com/maps/place/18%C2%B008'55.6%22N+74%C2%B034'17.5%22E/@18.1487753,74.5689515,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.1487753!4d74.5715264?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
    },
    {
        english: {
            division: "Lonand Division",
            address: "At Po. Nira Jaguar Mall Shivjal Infra Nira City Center Jain Mandir Neighbor T. Purandar Dist. Pune Pin code 412102.",
            branch: "Nira",
            main_office: false,
            contact: "9730477444",
            mapLocation: "https://www.google.com/maps?q=18.1048775,74.2109463&z=17&hl=en",
        },
        marathi: {
            division: "लोणंद विभाग",
            address: "मु. पो. निरा जग्वार मॉल शिवजल इन्फ्रा निरा सिटी सेंटर जैन मंदिर शेजारी ता. पुरंदर जि. पुणे .",
            branch: "निरा",
            main_office: false,
            contact: "9730477444",
            mapLocation: "https://www.google.com/maps?q=18.1048775,74.2109463&z=17&hl=en",
        },
    },

    //Wai Division 
    {
        english: {
            division: "Wai Division",
            address: "440 Bagul Apartment Ground Floor Opposite Lokmanya Tilak Library Ganpati Ali Wai.",
            contact: "9730937770",
            branch: "Divisional Office",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=17.9544355,73.8869547&z=17&hl=en",
        },
        marathi: {
            division: "वाई विभाग",
            address: "440 बागुल अपार्टमेंट तळ मजला लोकमान्य टिळक वाचनालय समोर गणपती आळी वाई.",
            contact: "9730937770",
            branch: "विभागीय कार्यालय वाई",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=17.9544355,73.8869547&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Wai Division",
            address: "440 Bagul Apartment Ground Floor Opposite Lokmanya Tilak Library Ganpati Ali Wai.",
            contact: "9766599625",
            branch: "Wai",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.9544355,73.8869547&z=17&hl=en",
        },
        marathi: {
            division: "वाई विभाग",
            address: "440 बागुल अपार्टमेंट तळ मजला लोकमान्य टिळक वाचनालय समोर गणपती आळी वाई.",
            contact: "9766599625",
            branch: "वाई",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.9544355,73.8869547&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Wai Division",
            address: "A/p Bhuinj , Tal - wai Dist-satara.",
            contact: "9766599624",
            branch: "Bhuinj",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.9544355,73.8869547&z=17&hl=en",
        },
        marathi: {
            division: "वाई विभाग",
            address: "ता - वाई जि.सातारा.",
            contact: "9766599624",
            branch: "भुईंज",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.9544355,73.8869547&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Wai Division",
            address: " At Post -  Karhar Main Road, Opposite Dutt Hospital Ta Javali District Satara.",
            contact: "9730600777",
            branch: "Karhar",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.8708082,73.8354574&z=17&hl=en",
        },
        marathi: {
            division: "वाई विभाग",
            address: "मु पो करहर मेन रोड , दत्त हॉस्पिटल समोर ता जावली जि सातारा.",
            contact: "9730600777",
            branch: "करहर",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.8708082,73.8354574&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Wai Division",
            address: "Hanuman Road, M.P.O. Panchgani.Ta-Mahabaleshwar.",
            contact: "9766599629",
            branch: "Panchgani",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.9247773,73.7987388&z=17&hl=en",
        },
        marathi: {
            division: "वाई विभाग",
            address: "हनुमान रोड,मु.पो. पांचगणी.ता-महाबळेश्वर.",
            contact: "9766599629",
            branch: "पांचगणी ",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.9247773,73.7987388&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Wai Division",
            address: "At.Po. Ozarde. Tal Wai Dist. Satara.",
            contact: "9766599631",
            branch: "Ozarde",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.9144846,73.9492738&z=17&hl=en",
        },
        marathi: {
            division: "वाई विभाग",
            address: " मु.पो. ओझर्डे.  ता. वाई जि. सातारा.",
            contact: "9766599631",
            branch: "ओझर्डे",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.9144846,73.9492738&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Wai Division",
            address: "Medha Kudal Road Opposite State Bank Medha T. Javali, Dist. Satara.",
            contact: "9503461114",
            branch: "Medha",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.7943285,73.8295533&z=17&hl=en",
        },
        marathi: {
            division: "वाई विभाग",
            address: "मेढा कुडाळ रोड, स्टेट बँकेच्या समोर मेढा ता. जावली, जि. सातारा.",
            contact: "9503461114",
            branch: "मेढा",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.7943285,73.8295533&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Wai Division",
            address: "At.Po Saigaon Tal. Javali Dist. Satara.",
            contact: "9503623081",
            branch: "Saigaon",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.7955276,73.9395703&z=17&hl=en",
        },
        marathi: {
            division: "वाई विभाग",
            address: "पत्ता.मु.पो सायगाव ता. जावली जि. सातारा .",
            contact: "9503623081",
            branch: "सायगाव",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.7955276,73.9395703&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Wai Division",
            address: "Opposite S T Stand, Kudal.",
            contact: "7722002354",
            branch: "Kudal",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.8358144,73.9184514&z=17&hl=en",
        },
        marathi: {
            division: "वाई विभाग",
            address: "एस. टी स्टॅन्ड समोर, कुडाळ.",
            contact: "7722002354",
            branch: "कुडाळ",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=17.8358144,73.9184514&z=17&hl=en",
        },
    },

    //Mumbai Division
    {
        english: {
            division: "Mumbai Division",
            address: "NL 1A Room No 39/1 sector 10 Nerul Navi Mumbaipin code.400706.",
            contact: "7757029577",
            branch: "Divisional Office Nerul",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=19.0370681,73.0174056&z=17&hl=en",
        },
        marathi: {
            division: "मुंबई विभाग",
            address: "एन एल 1 ए रूम नंबर 39/1 सेक्टर 10 नेरूळ नवी मुंबई 400706.",
            contact: "7757029577",
            branch: "विभागीय कार्यालय नेरूळ",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=19.0370681,73.0174056&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Mumbai Division",
            address: "NL 1A ROOM NO 39/1 SECTOR 10 NERUL NAVI MUMBAI.pin code.400706.",
            contact: "9766599636",
            branch: "Nerul",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=19.0370681,73.0174056&z=17&hl=en",
        },
        marathi: {
            division: "मुंबई विभाग",
            address: "एन एल 1 ए रूम नंबर 39/1 सेक्टर 10 नेरूळ नवी मुंबई.400706",
            contact: "9766599636",
            branch: "नेरूळ",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=19.0370681,73.0174056&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Mumbai Division",
            address: "Office no 01, Gomti Apt., Naviwadi, Dadishet Agyari Len,Chirabazar,Mumbai 400002.",
            contact: "9766599634",
            branch: "C ward Mumbai",
            main_office: false,
            mapLocation: "https://www.google.com/maps/place/18%C2%B056'58.7%22N+72%C2%B049'32.1%22E/@18.9496261,72.8230062,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.9496261!4d72.8255811?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
        marathi: {
            division: "मुंबई विभाग",
            address: "कार्यालय क्रमांक ०१, गोमती ॲप, नवीवाडी, दादीशेठ अग्यारी लेन, चिराबाजार, मुंबई 400002.",
            contact: "9766599634",
            branch: "सी वार्ड मुंबई",
            main_office: false,
            mapLocation: "https://www.google.com/maps/place/18%C2%B056'58.7%22N+72%C2%B049'32.1%22E/@18.9496261,72.8230062,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.9496261!4d72.8255811?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
    },
    {
        english: {
            division: "Mumbai Division",
            address: "Vaibhav Co Op Hau Society Plot No 256, C Wing, Flat No G3, Sector 1, Charkop, Kandivali (W), Mumbai 400067.",
            contact: "9766599635",
            branch: "Kandivali",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=19.2149355,72.8281347&z=17&hl=en",
        },
        marathi: {
            division: "मुंबई विभाग",
            address: "वैभव को ऑप हौ सोसायटी प्लॉट नं 256,सी विंग,फ्लॅट नंबर जी 3,सेक्टर 1, चारकोप, कांदिवली (प),मुंबई 400067.",
            contact: "9766599635",
            branch: "कांदिवली",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=19.2149355,72.8281347&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Mumbai Division",
            address: "hakti Co Op Hausing Society A Wing Shop No 110, 111 Vaman Hari Pethe Saraf Opposite Dutt Mandir Road MB Estate Virar West.",
            contact: "9766599637",
            branch: "Virar",
            main_office: false,
            mapLocation: "https://www.google.com/maps/place/19%C2%B027'12.6%22N+72%C2%B048'22.4%22E/@19.4534887,72.8036488,17z/data=!3m1!4b1!4m4!3m3!8m2!3d19.4534887!4d72.8062237?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
        marathi: {
            division: "मुंबई विभाग",
            address: "शक्ती को ऑप हौ सोसा A विंग शॉप नं 110 , 111 वामन हरी पेठे सराफ समोर दत्त मंदिर रोड एम बी इस्टेट विरार पश्चिम .pin code.400706.",
            contact: "9766599637",
            branch: "विरार",
            main_office: false,
            mapLocation: "https://www.google.com/maps/place/19%C2%B027'12.6%22N+72%C2%B048'22.4%22E/@19.4534887,72.8036488,17z/data=!3m1!4b1!4m4!3m3!8m2!3d19.4534887!4d72.8062237?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
    },
    {
        english: {
            division: "Mumbai Division",
            address: "Nageshwar Compound, Building No B, Block No 105-106, Purna Bus Stop, Purna. Bhiwandi Pin no.421302.",
            contact: "9766599638",
            branch: "Bhiwandi",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.0588647,74.0176132&z=17&hl=en",
        },
        marathi: {
            division: "मुंबई विभाग",
            address: "नागेश्वर कंपाऊंड, बिल्डिंग नं बी, गाळा नं 105-106, पुर्णा बस स्टॉप, पुर्णा. भिवंडी. पिन कोड.421302.",
            contact: "9766599638",
            branch: "भिवंडी",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.0588647,74.0176132&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Mumbai Division",
            address: "Satyaketu Complex, Shop No. 26 Plot No. 9, Sector 35, Mansarovar Station Road Kamothe Navi Mumbai 410209.",
            contact: "9503056646",
            branch: "Kamothe Navi Mumbai",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=19.0227216,73.0900905&z=17&hl=en",
        },
        marathi: {
            division: "मुंबई विभाग",
            address: "सत्यकेतु कॉम्प्लेक्स ,शॉप नं 26 प्लॉट नं 9 ,सेक्टर 35 ,मानसरोवर स्टेशन रोड कामोठे नवी मुंबई 410209.",
            contact: "9503056646",
            branch: "कामोठे नवी मुंबई",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=19.0227216,73.0900905&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Mumbai Division",
            address: "SS 03 Room No, 270 Sector 07 Koparkhairane Navi Mumbai Pin code 400709.",
            contact: "7774076470",
            branch: "KoparKhairane",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=19.1022998,73.0054618&z=17&hl=en",
        },
        marathi: {
            division: "मुंबई विभाग",
            address: "एस एस 03 रूम न,270 सेक्टर 07 कोपरखैरणे नवी मुंबई  पिन कोड 400709.",
            contact: "7774076470",
            branch: "कोपरखैरणे",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=19.1022998,73.0054618&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Mumbai Division",
            address: "Plot No 11 BA, Shiv Mandir, 1st Floor, Sector-3 Airoli Navi Mumbai 400708.",
            contact: "7774076370",
            branch: "Airoli",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=19.160554885864258,72.99662017822266&z=17&hl=en",
        },
        marathi: {
            division: "मुंबई विभाग",
            address: "प्लॉट नं 11 बी ए , शिवमंदिर , पहिला मजला , सेक्टर - 3 ऐरोली नवी मुंबई 400708.",
            contact: "7774076370",
            branch: "ऐरोली",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=19.160554885864258,72.99662017822266&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Mumbai Division",
            address: " Shri Narayan Residency, Ground Floor, Shop No. 2, Sector - 3, Ulve, Navi Mumbai, Pin Code - 410206.",
            contact: "8956053276",
            branch: "Ulve",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.9835489,73.0255549&z=17&hl=en",
        },
        marathi: {
            division: "मुंबई विभाग",
            address:  "श्री नारायण रेसिडेन्सी, ग्राउंड फ्लोअर, शॉप नंबर 2, सेक्टर - 3, उलवे, नवी मुंबई,  पिन कोड - 410206  .",
            contact: "8956053276",
            branch: "उलवे",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.9835489,73.0255549&z=17&hl=en",
        },
    },

    //Sarola Division
    {
        english: {
            division: "Sarola Division",
            address: "At Post - Sarola, Ta Bhor District Pune.",
            contact: "8956053278",
            branch: "Divisional Office Sarola",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=18.177063,73.9560929&z=17&hl=en",
        },
        marathi: {
            division: "सारोळा विभाग",
            address:"मु पो सारोळा ता भोर जि पुणे.",
            contact: "8956053278",
            branch: "विभागीय कार्यालय सारोळा",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=18.177063,73.9560929&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Sarola Division ",
            address: "At Post - Sarola, Ta Bhor District Pune.",
            contact: "9766599616",
            branch: "Sarola",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.177063,73.9560929&z=17&hl=en",
        },
        marathi: {
            division: "सारोळा विभाग",
            address:" मु पो सारोळा ता भोर जि पुणे .",
            contact: "9766599616",
            main_office: false,
            branch: "सारोळा",
            mapLocation: "https://www.google.com/maps?q=18.177063,73.9560929&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Sarola Division ",
            address: "At. Post. Khandala (Moti Chowk) Taluka. Khandala  Dist.Satara.",
            contact: "9503461113",
            branch: "Khandala",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.0588647,74.0176132&z=17&hl=en",
        },
        marathi: {
            division: "सारोळा विभाग",
            address:" मु. पोस्ट. खंडाळा ( मोती चौक ) तालुका. खंडाळा जिल्हा.सातारा.",
            contact: "9503461113",
            branch: "खंडाळा",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.0588647,74.0176132&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Sarola Division ",
            address: "At Post - Shirwal, (Shivaji Chowk) Tal - Khandala  District - Satara Pin Code 412801.",
            contact: "9503461115",
            branch: "Shirwal",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.1469348,73.9767817&z=17&hl=en",
        },
        marathi: {
            division: "सारोळा विभाग",
            address:"मु. पो. शिरवळ,( शिवाजी चौक) ता. खंडाळा  जि.सातारा पिन कोड 412801",
            contact: "9503461115",
            branch: "शिरवळ",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.1469348,73.9767817&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Sarola Division ",
            address: "Swapnalok Township Shop No. 16 -17-18 Taluka - Bhor, District - Pune.",
            contact: "9766599619",
            branch: "Nasrapur",
            main_office: false,
            mapLocation: "https://www.google.com/maps/place/18%C2%B015'05.1%22N+73%C2%B052'51.8%22E/@18.2514104,73.8784739,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.2514104!4d73.8810488?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
        marathi: {
            division: "सारोळा विभाग",
            address:"स्वप्नलोक टाऊनशिप शॉप नंबर 16 -17-18, नसरापूर  तालुका -भोर, जिल्हा- पुणे.",
            contact: "9766599619",
            branch: "नसरापूर",
            main_office: false,
            mapLocation: "https://www.google.com/maps/place/18%C2%B015'05.1%22N+73%C2%B052'51.8%22E/@18.2514104,73.8784739,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.2514104!4d73.8810488?hl=en&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D",
        },
    },
    {
        english: {
            division: "Sarola Division ",
            address: "Bajrang lane, Kumbhar Tekdi Talim Mandal, Bhor ,Dist. Pune.",
            contact: "9767633532",
            branch: "Bhor",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.1508165,73.8450868&z=17&hl=en",
        },
        marathi: {
            division: "सारोळा विभाग",
            address:"बजरंग आळी कुंभार टेकडी तालीम मंडळ भोर.",
            contact: "9767633532",
            branch: "भोर",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.1508165,73.8450868&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Sarola Division ",
            address: "At post uttam nana pangare bilding main road Velhe Tal Velhe District Pune Pincode 412212.",
            contact: "8956053275",
            branch: "Velhe",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.2944132,73.6368521&z=17&hl=en",
        },
        marathi: {
            division: "सारोळा विभाग",
            address:"उत्तम नाना पांगारे बिल्डिंग मुख्य रस्ता, मु.पो. वेल्हे ता वेल्हे जिल्हा पुणे पिनकोड 412212.",
            contact: "8956053275",
            branch: "वेल्हे",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.2944132,73.6368521&z=17&hl=en",
        },
    },

    //Pune Division
    {
        english: {
            division: "Pune Division",
            address: "Akshay Garden Society Sambhaji Nagar Suyog Hospital Below Shop No. 11.12 Dhankawadi Pune 411043.",
            contact: "9766599670",
            branch: "Divisional Office Pune",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=18.4682171,73.8555068&z=17&hl=en",
        },
        marathi: {
            division: "पुणे विभाग",
            address:"अक्षय गार्डन सोसायटी संभाजी नगर सुयोग हॅसपिटल खाली शॉप नंबर 11.12 धनकवडी पुणे 411043.",
            contact: "9766599670",
            branch: "विभागीय कार्यालय पुणे",
            main_office: true,
            mapLocation: "https://www.google.com/maps?q=18.4682171,73.8555068&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Pune Division",
            address: "Akshay Garden Society Sambhaji Nagar Suyog Hospital Below Shop No. 11.12 Dhankawadi Pune 411043.",
            contact: "9503056643",
            branch: "Dhankawadi",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.4682171,73.8555068&z=17&hl=en",
        },
        marathi: {
            division: "पुणे विभाग",
            address:"अक्षय गार्डन सोसायटी संभाजी नगर सुयोग हॅसपिटल खाली शॉप नंबर 11.12 धनकवडी पुणे 411043.",
            contact: "9503056643",
            branch: "धनकवडी",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.4682171,73.8555068&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Pune Division",
            address: "Shivam market, Koyananagar, Sone corner, Chinchwad, Pune.",
            contact: "9503056645",
            branch: "Pimpari-Chinchwad",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.669557,73.7976186&z=17&hl=en",
        },
        marathi: {
            division: "पुणे विभाग",
            address:"शिवम मार्केट बि.नं.3,गाळा नं.12,13,14 साने चाैक,साई हाॅस्पिटलच्या पाठीमागे कोयनानगर,चिंचवड,पुणे.",
            contact: "9503056645",
            branch: "पिंपरी चिंचवड",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.4682171,73.8555068&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Pune Division",
            address: "Near Dada Jadhavrao Sanskrutik Bhavan, Market Yard, Saswad, Tal. Saswad, Dist. Pune 412301.",
            contact: "9766516665",
            branch: "Saswad",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.3469357,74.029902&z=17&hl=en",
        },
        marathi: {
            division: "पुणे विभाग",
            address:"पत्ता - दादा जाधवराव सांस्कृतिक भवन शेजारी,पी डी सी बँकेसमोर,मार्केट यार्ड सासवड. ता.पुरंदर जि पुणे. पिन 412301.",
            contact: "9766516665",
            branch: "सासवड",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.3469357,74.029902&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Pune Division",
            address: " Survey no. 12/1B, Tulsi Complex, Opposite Ravi Darshan, Solapur Road, Hadapsar, Pune -411028.",
            contact: "8669668275",
            branch: "Hadapsar",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.4998288,73.9442895&z=17&hl=en",
        },
        marathi: {
            division: "पुणे विभाग",
            address:"सर्वे नं. 12/1 बी, तुलसी कॉम्प्लेक्स, रवी दर्शन समोर, सोलापूर रोड, हडपसर, पुणे -411028.",
            contact: "8669668275",
            branch: "हडपसर",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.4998288,73.9442895&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Pune Division",
            address: "Lipane colony, Jambhulwadi road, Aambegaon Khurd, Pune.",
            contact: "8669668276",
            branch: "Aambegaon Khurd",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.4369971,73.846617&z=17&hl=en",
        },
        marathi: {
            division: "पुणे विभाग",
            address:" ब्लीस काॅस्ट बिल्डिंग ई विंग   गाळा नं.1,2,3 लिपाणे वस्ती जांभुळवाडी रोड आंबेगाव खुर्द पुणे.",
            contact: "8669668276",
            branch: "आंबेगाव खुर्द",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.4369971,73.846617&z=17&hl=en",
        },
    },
    {
        english: {
            division: "Pune Division",
            address: "Survey No.- 36/01, Shivlakshya Heights, 1st Floor, Katepuram Chowk Road, Pimple Gurav- 411061.",
            contact: "8956053284",
            branch: "Pimple Gurav",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.58525,73.8217594&z=17&hl=en",
        },
        marathi: {
            division: "पुणे विभाग",
            address:"सर्वे नं 36/1, पहिला मजला,रामकृष्ण मंगल कार्यालय जवळ, सेंट्रल बँक शेजारी, काटेपुरम चौक रोड, पिंपळे गुरव 411061.",
            contact: "8956053284",
            branch: "नवी सांगवी पिंपळेगुरव",
            main_office: false,
            mapLocation: "https://www.google.com/maps?q=18.58525,73.8217594&z=17&hl=en",
        },
    },

];

import React, { useState, useEffect } from "react";
import { Image, Tab, Tabs } from "react-bootstrap";
import { motion } from "framer-motion";
import { Tilt } from "react-tilt";
import { useQuery } from "@tanstack/react-query";
import useLanguage from "../customHook/useLanguage";
import { getSchemesDataHome } from "../http/httpRequest";
import LoadingLayout from "../loadingLayout/LoadingLayout";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const [selectedTab, setSelectedTab] = useState(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  // Animation variants
  const variants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  const bgImageNews = {
    backgroundImage: "url('news-enent.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  const languageState = useLanguage();

  const { data: schemesData, isLoading: schemesDataIsLoading } = useQuery({
    queryKey: ["schemes-data-home", languageState],
    queryFn: getSchemesDataHome,
    gcTime: Infinity,
    staleTime: 0,
  });

  const handleNavigateScheme = (scheme_id) => {
    navigate(`/scheme-details/${scheme_id}`)
  }


  useEffect(() => {
    if (schemesData?.data && languageState) {
      setSelectedTab(schemesData.data[languageState][0]?.id);
    }
  }, [schemesData, languageState]);

  if (schemesDataIsLoading) return <LoadingLayout />;

  return (
    <section
      className="services-content p-32px mt-4"
      data-aos="fade-up"
      style={bgImageNews}
    >
      <div className="container">
        <div className="text-center mb-3 mb-lg-5" data-aos="fade-right">
          <h6 className="txt-theme">{t("schemes")}</h6>
          <h2 className="fw-bold txt-gray-dark">{t("our_latest_schemes")}</h2>
        </div>

        {/* Tabs Container for all schemes */}
        <Tabs
          activeKey={selectedTab}
          onSelect={(key) => setSelectedTab(key)}
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          {schemesData?.data &&
            schemesData?.data[languageState]?.map((scheme) => (
              <Tab eventKey={scheme?.id} title={scheme?.scheme_title} key={scheme?.id}>
                <motion.div
                  className="row"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={variants}
                  transition={{ duration: 0.7 }}
                >
                  <div className="col-lg-7 mb-3 mb-lg-0">
                    <h5>{scheme?.scheme_title}</h5>
                    <p className="opacity-75" dangerouslySetInnerHTML={{
                      __html: scheme?.scheme_description,
                    }} />
                    <button className="download-btn bg-secondary-theme mt-2" onClick={()=> handleNavigateScheme(scheme?.id)}>
                      <span>{t("view_details")}</span>
                    </button>
                  </div>
                  <div className="col-lg-5 mt-5 mt-lg-0 ">
                    <Tilt
                      options={{
                        max: 10,
                        scale: 1.01,
                        speed: 900,
                        glare: true,
                        "max-glare": 0.5,
                      }}
                    >
                      <div className="text-center services-image ">
                        <Image
                          src={scheme?.scheme_image}
                          alt={scheme?.scheme_title}
                          className="w-100"
                        />
                      </div>
                    </Tilt>
                  </div>
                </motion.div>
              </Tab>
            ))}
        </Tabs>
      </div>
    </section>
  );
};

export default Services;

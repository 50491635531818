import React from "react";
import Home from "./landingPage/Home";
import About from "./landingPage/About";
import Services from "./landingPage/Services";
import NewsBlog from "./landingPage/NewsBlog";
import Testimonials from "./landingPage/Testimonials";
import Download from "./landingPage/Download";
import Scheme from "./landingPage/Scheme";
import { getBannerData, getNewsAndEventsList } from "./http/httpRequest";
import useLanguage from "./customHook/useLanguage";
import { useQuery } from "@tanstack/react-query";
import LoadingLayout from "./loadingLayout/LoadingLayout";
import FixedButtons from "./fixed_buttons/FixedButtons";

const LandingPage = () => {
  const languageState = useLanguage();
  const { data: bannerData, isLoading: bannerDataIsLoading } = useQuery({
    queryKey: ["get-banner", languageState],
    queryFn: getBannerData,
    gcTime: Infinity,
    staleTime: 0,
  });

  const { data: newsEventsData, isLoading: newsEventsIsLoading } = useQuery({
      queryKey: ["news-events", languageState],
      queryFn: getNewsAndEventsList,
      gcTime: Infinity,
      staleTime: 0,
  });

  if(bannerDataIsLoading && newsEventsIsLoading) return <LoadingLayout/>

  return (
    <>
      <Home bannerData={bannerData?.data && bannerData?.data} languageState={languageState} />
      <About />
      <Services />
      <NewsBlog newsEventsData={newsEventsData?.data && newsEventsData?.data} languageState={languageState}/>
      {/* <Testimonials /> */}
      <Download />
      <Scheme />
    </>
  );
};

export default LandingPage;

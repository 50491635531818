export const divisionsData = {
    divisions: [
        {
            divisionName:
            {
                english: "Main Division",
                marathi: "मुख्य विभाग"
            },
            branches: [
                {
                    branchName: {
                        english: "Main Divisional Office",
                        marathi: "मुख्य विभागीय कार्यालय"
                    },
                },
                {
                    branchName: {
                        english: "Main Branch",
                        marathi: "मुख्य शाखा"
                    },
                },
                {
                    branchName: {
                        english: "Ganeshmandir",
                        marathi: "गणेशमंदिर"
                    },
                },
                {
                    branchName: {
                        english: "S T Stand",
                        marathi: "एस.टी स्टॅड"
                    },
                },
                {
                    branchName: {
                        english: "Sarkalwadi",
                        marathi: "सर्कलवाडी"
                    },
                },
                {
                    branchName: {
                        english: "Vathar Station",
                        marathi: "वाठार स्टेशन"
                    },
                },
                {
                    branchName: {
                        english: "Sonake",
                        marathi: "सोनके"
                    },
                },
                {
                    branchName: {
                        english: "Bhadle",
                        marathi: "भाडळे"
                    },
                },
            ]
        },

        //Satara Division
        {
            divisionName:
            {
                english: "Satara Division",
                marathi: "सातारा विभाग"
            },
            branches: [
                {
                    branchName: {
                        english: "Satara Divisional Office",
                        marathi: "विभागीय कार्यालय सातारा"
                    },
                },
                {
                    branchName: {
                        english: "Satara",
                        marathi: "सातारा"
                    },
                },
                {
                    branchName: {
                        english: "Koregaon",
                        marathi: "कोरेगांव"
                    },
                },
                {
                    branchName: {
                        english: "Rahimatpur",
                        marathi: "रहिमतपूर"
                    },
                },
                {
                    branchName: {
                        english: "Saap",
                        marathi: "साप"
                    },
                },
                {
                    branchName: {
                        english: "Sadarbazar",
                        marathi: "सदरबाजार"
                    },
                },
                {
                    branchName: {
                        english: "Kolhapur",
                        marathi: "कोल्हापूर"
                    },
                },
                {
                    branchName: {
                        english: "Umbraj",
                        marathi: "उंब्रज"
                    },
                },
                {
                    branchName: {
                        english: "Koregaon",
                        marathi: "कोरेगाव"
                    },
                },
            ]
        },

        //Vaduj Division
        {
            divisionName:
            {
                english: "Vaduj Division",
                marathi: "वडूज विभाग"
            },
            branches: [
                {
                    branchName: {
                        english: "Divisional Office",
                        marathi: "विभागीय कार्यालय"
                    },
                },
                {
                    branchName: {
                        english: "Vaduj",
                        marathi: "वडूज"
                    },
                },
                {
                    branchName: {
                        english: "Pusegaon",
                        marathi: "पुसेगाव"
                    },
                },
                {
                    branchName: {
                        english: "Pusesawali",
                        marathi: "पुसेसावळी"
                    },
                },
                {
                    branchName: {
                        english: "Mhaswad",
                        marathi: "म्हसवड"
                    },
                },
                {
                    branchName: {
                        english: "Mayani",
                        marathi: "मायणी"
                    },
                },
                {
                    branchName: {
                        english: "Dahivadi",
                        marathi: "दहिवडी"
                    },
                },
            ]
        },
        //Lonand Division
        {
            divisionName:
            {
                english: "Lonand Division",
                marathi: "लोणंद विभाग"
            },
            branches: [
                {
                    branchName: {
                        english: "Divisional Office",
                        marathi: "विभागीय कार्यालय"
                    },
                },
                {
                    branchName: {
                        english: "Baramati (Kasba)",
                        marathi: "बारामती (कसबा)"
                    },
                },
                {
                    branchName: {
                        english: "Lonand",
                        marathi: "लोणंद"
                    },
                },
                {
                    branchName: {
                        english: "Phaltan",
                        marathi: "फलटण"
                    },
                },
                {
                    branchName: {
                        english: "Sakharwadi",
                        marathi: "साखरवाडी"
                    },
                },
                {
                    branchName: {
                        english: "Baramati City",
                        marathi: "बारामती शहर"
                    },
                },
                {
                    branchName: {
                        english: "Nira",
                        marathi: "निरा"
                    },
                },
            ]
        },

        //Wai Division

        {
            divisionName:
            {
                english: "Wai Division",
                marathi: "वाई विभाग"
            },
            branches: [
                {
                    branchName: {
                        english: "Divisional Office",
                        marathi: "विभागीय कार्यालय"
                    },
                },
                {
                    branchName: {
                        english: "Wai",
                        marathi: "वाई"
                    },
                },
                {
                    branchName: {
                        english: "Bhuinj",
                        marathi: "भुईंज"
                    },
                },
                {
                    branchName: {
                        english: "Karhar",
                        marathi: "करहर"
                    },
                },
                {
                    branchName: {
                        english: "Panchgani",
                        marathi: "पांचगणी"
                    },
                },
                {
                    branchName: {
                        english: "Ozarde",
                        marathi: "ओझर्डे"
                    },
                },
                {
                    branchName: {
                        english: "Medha",
                        marathi: "मेढा"
                    },
                },
                {
                    branchName: {
                        english: "Saigaon",
                        marathi: "सायगाव"
                    },
                },
                {
                    branchName: {
                        english: "Kudal",
                        marathi: "कुडाळ"
                    },
                },
            ]
        },

        //Mumbai Division
        {
            divisionName:
            {
                english: "Mumbai Division",
                marathi: "मुंबई विभाग"
            },
            branches: [
                {
                    branchName: {
                        english: "Divisional Office Nerul",
                        marathi: "विभागीय कार्यालय नेरूळ"
                    },
                },
                {
                    branchName: {
                        english: "Nerul",
                        marathi: "नेरूळ"
                    },
                },
                {
                    branchName: {
                        english: "C ward Mumbai",
                        marathi: "प्रभाग सी मुंबई"
                    },
                },
                {
                    branchName: {
                        english: "Kandivali",
                        marathi: "कांदिवली"
                    },
                },
                {
                    branchName: {
                        english: "Virar",
                        marathi: "विरार"
                    },
                },
                {
                    branchName: {
                        english: "Bhiwandi",
                        marathi: "भिवंडी"
                    },
                },
                {
                    branchName: {
                        english: "Kamothe Navi Mumbai",
                        marathi: "कामोठे नवी मुंबई"
                    },
                },
                {
                    branchName: {
                        english: "KoparKhairane",
                        marathi: "कोपरखैरणे"
                    },
                },
                {
                    branchName: {
                        english: "Airoli",
                        marathi: "ऐरोली"
                    },
                },
                {
                    branchName: {
                        english: "Ulve",
                        marathi: "उलवे"
                    },
                },
            ]
        },

         //Sarola Division

         {
            divisionName:
            {
                english: "Sarola Division",
                marathi: "सारोळा विभाग"
            },
            branches: [
                {
                    branchName: {
                        english: "Divisional Office Nerul",
                        marathi: "विभागीय कार्यालय सारोळा"
                    },
                },
                {
                    branchName: {
                        english: "Sarola",
                        marathi: "सारोळा"
                    },
                },
                {
                    branchName: {
                        english: "Khandala",
                        marathi: "खंडाळा"
                    },
                },
                {
                    branchName: {
                        english: "Shirwal",
                        marathi: "शिरवळ"
                    },
                },
                {
                    branchName: {
                        english: "Nasrapur",
                        marathi: "नसरापूर"
                    },
                },
                {
                    branchName: {
                        english: "Bhor",
                        marathi: "भोर"
                    },
                },
                {
                    branchName: {
                        english: "Velhe",
                        marathi: "वेल्हे"
                    },
                },
            ]
        },

        //Pune Division
        {
            divisionName:
            {
                english: "Pune Division",
                marathi: "पुणे विभाग"
            },
            branches: [
                {
                    branchName: {
                        english: "Divisional Office Pune",
                        marathi: "विभागीय कार्यालय पुणे"
                    },
                },
                {
                    branchName: {
                        english: "Dhankawadi",
                        marathi: "धनकवडी"
                    },
                },
                {
                    branchName: {
                        english: "Pimpari-Chinchwad",
                        marathi: "पिंपरी चिंचवड"
                    },
                },
                {
                    branchName: {
                        english: "Saswad",
                        marathi: "सासवड"
                    },
                },
                {
                    branchName: {
                        english: "Hadapsar",
                        marathi: "हडपसर"
                    },
                },
                {
                    branchName: {
                        english: "Aambegaon Khurd",
                        marathi: "आंबेगाव खुर्द"
                    },
                },
                {
                    branchName: {
                        english: "Pimple Gurav",
                        marathi: "नवी सांगवी पिंपळेगुरव"
                    },
                }
            ]
        }
    ]
};




import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const BlogSidebar = ({ categoriesData, languageState }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("otherlinks")

  const handleClickCategory = (id) => {
    navigate(`/blogs?limit=10&page=1&category=${id}`);
  }


  return (
    <>
      <div className="sticky-top row d-flex justify-content-center " style={{
        zIndex: 50,
      }}>
        <div className="border rounded-4 p-3 mb-3">
          <div className="rounded p-2 fw-bold bg-secondary-theme d-inline-block txt-14 mb-3">
            {t("recent_posts")}
          </div>
          {
            categoriesData &&
              categoriesData[languageState]?.blogs?.length > 0 ? (
              categoriesData[languageState]?.blogs?.map((blog) => {
                return (
                  <>
                    <ul className="list-group my-2 text-start list-group-flush list-unstyled" key={blog?.id}>

                      <li className="blog-sidebar-content">
                        <Link className="text-decoration-none text-light" to={`/blogs-details/${blog.blog_slug}`}>
                          {blog?.blog_title}
                        </Link>
                      </li>
                    </ul>
                  </>
                )
              })
            ) : (
              <div>{t("recent_post_available")}</div>
            )
          }
        </div>
        <div className="border rounded-4 p-3 mb-3">
          <div className="rounded p-2 fw-bold bg-secondary-theme d-inline-block txt-14 mb-3">
            {t("categories")}
          </div>
          {
            categoriesData &&
              categoriesData[languageState]?.categories?.length > 0 ? (
              categoriesData[languageState]?.categories?.map((category) => {
                return (
                  <>
                    <ul className="list-group my-2 text-start list-group-flush list-unstyled cursor-pointer" key={category.id} >
                      <li className="blog-sidebar-content">
                        <div className=" text-dark" onClick={() => handleClickCategory(category.id)}>
                          {category.category_name}
                        </div>
                      </li>
                    </ul>
                  </>
                )
              })
            ) : (
              <div>{t("categories_available")}</div>
            )
            
          }
        </div>
      </div>
    </>
  );
};

export default BlogSidebar;

import React from 'react';
import './videogallery.css';
import { useTranslation } from 'react-i18next';

function VideoGallery() {
    const { t } = useTranslation("header");
    return (
        <div className="container py-4">
            <div className="row">
                <div className="text-center mb-3 my-lg-4">
                    <h2 className="fw-normal  d-inline-block position-relative custom-heading">
                        {t("video")} <span className="head-sub-text">{t("gallery")}</span>
                    </h2>
                </div>
            </div>
            <div className="row g-4"> {/* g-4 adds consistent gap between grid items */}
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card shadow-sm rounded p-3 h-100">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/GkEX_rJFbQc?si=B1XB32wRaNigyp0U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <h5 className="mt-3 text-start">पिंपोडे बु येथे झालेल्या पत्रकार परिषदेत मा. संस्थापक रामराव लेंभे यांनी दिलेली मुलाखत</h5>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card shadow-sm rounded p-3 h-100">
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/JTG0_VwiW8o?si=HILb1QJN6Llf5S92"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                        <h5 className="mt-3 text-start">मा. संचालक मंडळ.</h5>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card shadow-sm rounded p-3 h-100">
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/AJCngkDne3M?si=xUK611ba9XQwUHve"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                        <h5 className="mt-3 text-start">संस्थेची ठळक वैशिष्ट्ये....</h5>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card shadow-sm rounded p-3 h-100">
                        <iframe
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/n1GPYa22SUc?si=IMilKZX61lureXWE"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                        <h5 className="mt-3 text-start">पिंपळे गुरव शाखा मार्केटिंग व्हिडिओ</h5>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default VideoGallery;



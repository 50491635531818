import { Button } from "bootstrap";
import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Download = () => {
  const { t } = useTranslation();
  const downloadBg = {
    backgroundImage: "url('download-bg.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "#fff",
    height: "200px",
  };
  return (
    <>
      <section className="download-content mt-4">
        {/* <div className="text-lg-end container">
          <h2 className=" mb-3">
            Experience Seamless Banking: Digital Banking
          </h2>
          <button className="d-flex align-items-center justify-content-center gap-2 download-btn d-lg-inline">
            <span>Download App</span>
            <i className="bx bx-chevrons-right"></i>
          </button>
        </div> */}
        <div className="container">
          <div className="download-bg row  p-32px rounded-top-4">
            <div className="col-md-6 col-lg-3 mb-3">
              <h4 className="text-muted mb-3 fw-bold">{t("quickLinks")}</h4>
              <div className="branch-locator">
                <Link to="/branches">
                  <button
                    id="branch-dropdown"
                  >
                    <i className="bx bxs-bank fs-5"></i>{" "}
                    <span >{t("our_branches")}</span>
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-3">
              <h4 className="text-muted mb-3 fw-bold">{t("followUs")}</h4>
              <div className="footer-social-icon mt-3">
                <a href="https://www.facebook.com/profile.php?id=61571043789637&mibextid=ZbWKwL" target="_blanks">
                  <i className="bx bxl-facebook facebook-bg text-white fs-4" />
                </a>
                <a href="https://www.instagram.com/chhatrapatisambhajipatsanstha?igsh=bHl6M3BxMDk5andl" target="_blanks">
                  <i className="bx bxl-instagram instagram-gradient  text-white fs-4" />
                </a>
                <a href="https://www.youtube.com/channel/UCig6ddhkIpJPVEZG_kXH6Ww" target="_blanks">
                  <i className="bx bxl-youtube youtube-bg text-white fs-4" />
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 mb-3">
              <div>
                <h4 className="text-muted mb-3 fw-bold">
                  {t("downloadOurMobileBankingApp")}
                </h4>
                <div className="d-flex gap-3 mt-4">
                  <Link to="https://play.google.com/store/apps/details?id=com.threestar.chhmobile&pcampaignid=web_share" target="_blanks">
                    {" "}
                    <img
                      src="app-store.svg"
                      alt="appstore"
                      style={{ width: "150px" }}
                    />
                  </Link>
                  <Link to="https://apps.apple.com/in/app/chh-mobile/id1662308118" target="_blanks">
                    {" "}
                    <img
                      src="google-play.svg"
                      alt="google-play"
                      style={{ width: "150px" }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Download;
